import { JobRole } from "../models/vcloudsmart";

export const JOB_ROLES: JobRole[] = [
  "Leadership",
  "Product",
  "Engineering",
  "FinOps",
  "Finance",
  "Sustainability",
  "ITAM",
  "ITSM",
  "Procurement",
  "Operations",
  "Security",
];
