import { Injectable } from "@angular/core";
import { ChartType } from "angular-google-charts";

import { ColorScheme } from "../models/customisation";
import { colorSchemes, DEFAULT_COLORSCHEME } from "../constant/color-schemes";
import { getEmailRegexes } from "../constant/emails";

const CONSISTENT_COLORS: { [label: string]: string } = {};
const LETTERS = "0123456789ABCDEF";
const WORD_REGEX = /[a-z]{2,}/gi;
const VODAFONE_DOMAIN_REGEX = /^[^@]+@(voda|vantage).*$/i;

@Injectable({
  providedIn: "root",
})
export class TenantService {
  public readonly colorSchemes: { [scheme: string]: ColorScheme } = colorSchemes;
  public readonly availableColorSchemes = Object.keys(this.colorSchemes);

  public getColorForLabel(label: string): string {
    const labelLower: string = this.findAllMatches(WORD_REGEX, label)
      .map((w) => w.toLowerCase().trim())
      .join(" ");
    if (labelLower.includes("actual")) {
      return this.colors.primary;
    }
    if (labelLower.includes("vpc")) {
      return this.colors.warning;
    }
    if (labelLower.includes("usage")) {
      return this.colors.danger;
    }
    if (
      labelLower.includes("support") ||
      labelLower.includes("credit") ||
      labelLower.includes("promotion") ||
      labelLower.includes("discount")
    ) {
      return this.colors.success;
    }
    if (labelLower.includes("pcs")) {
      return this.colors.info;
    }
    if (labelLower.includes("other") || labelLower.includes("org")) {
      return this.colors.secondary;
    }
    if (labelLower.includes("total cost")) {
      return this.colors.dark;
    }
    if (CONSISTENT_COLORS[labelLower]) {
      return CONSISTENT_COLORS[labelLower];
    }
    const c: string = this.getRandomColorInHex();
    CONSISTENT_COLORS[labelLower] = c;
    return c;
  }

  public get colorScheme(): string {
    const s = localStorage.getItem("colorScheme");
    if (s && this.availableColorSchemes.includes(s)) {
      return s;
    } else {
      return DEFAULT_COLORSCHEME;
    }
  }

  public get colors(): ColorScheme {
    return this.colorSchemes[this.colorScheme];
  }

  public set colorScheme(value: string) {
    localStorage.setItem("colorScheme", value);
  }

  public getRandomColorInHex(): string {
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += LETTERS[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  public getDefaultColorScheme(email: string): string {
    const s = localStorage.getItem("colorScheme");
    if (s && this.availableColorSchemes.includes(s)) {
      return s;
    }
    if (VODAFONE_DOMAIN_REGEX.test(email)) {
      return "vodafone";
    }
    return "default";
  }

  public getEmailDomainRegexes(): { REGEX_EMAIL: RegExp; REGEX_EMAIL_STR: string } {
    return getEmailRegexes();
  }

  public customiseColorScheme(s: string): void {
    let scheme = DEFAULT_COLORSCHEME;
    if (s && this.availableColorSchemes.includes(s)) {
      scheme = s;
    }
    const r = document.querySelector(":root") as HTMLElement;
    for (const [k, v] of Object.entries(this.colorSchemes[scheme])) {
      r.style.setProperty(`--${k}`, v);
    }
  }

  public getColors(n: number, chartType: string | ChartType): string[] {
    const color = this.colors.primary;
    if (chartType === "PieChart") {
      const hslColor = this.hexToHSL(color);
      return Array(n)
        .fill(null)
        .map((_, idx) => this.getBarColour(hslColor, (idx + 1) / n));
    } else {
      return [color];
    }
  }

  public getValueInBetween(low: number, high: number, percentage: number): number {
    return percentage * (high - low) + low;
  }

  public getBarColour({ h, s, l }: { h: number; s: number; l: number }, percentage: number): string {
    l = Math.ceil(this.getValueInBetween(Math.max(0, Math.floor(l * 0.1)), l, percentage));
    return this.hslToHex(Math.floor(h), Math.floor(s), l).slice(0, 7);
  }

  // https://css-tricks.com/converting-color-spaces-in-javascript/
  public hexToHSL(H: string): { h: number; s: number; l: number } {
    // Convert hex to RGB first
    let r: number | string = 0;
    let g: number | string = 0;
    let b: number | string = 0;

    if (H.length == 4) {
      r = `0x${H[1]}${H[1]}`;
      g = `0x${H[2]}${H[2]}`;
      b = `0x${H[3]}${H[3]}`;
    } else if (H.length == 7) {
      r = `0x${H[1]}${H[2]}`;
      g = `0x${H[3]}${H[4]}`;
      b = `0x${H[5]}${H[6]}`;
    }
    // Then to HSL
    // @ts-ignore
    r /= 255;
    // @ts-ignore
    g /= 255;
    // @ts-ignore
    b /= 255;
    // @ts-ignore
    let cmin = Math.min(r, g, b);
    // @ts-ignore
    let cmax = Math.max(r, g, b);
    let delta = cmax - cmin;
    let h = 0;
    let s = 0;
    let l = 0;

    if (delta == 0) h = 0;
    // @ts-ignore
    else if (cmax == r) h = ((g - b) / delta) % 6;
    // @ts-ignore
    else if (cmax == g) h = (b - r) / delta + 2;
    // @ts-ignore
    else h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    if (h < 0) h += 360;

    l = (cmax + cmin) / 2;
    s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return { h, s, l };
  }

  private findAllMatches(r: RegExp, s: string): Array<string> {
    const matches = [];
    let match = r.exec(s);
    while (match) {
      matches.push(match[0]);
      match = r.exec(s);
    }
    return matches;
  }

  public hslToHex(h: number, s: number, l: number): string {
    l /= 100;
    const a = (s * Math.min(l, 1 - l)) / 100;
    const f = (n: number): string => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color)
        .toString(16)
        .padStart(2, "0"); // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
  }
}
