import { Injectable } from "@angular/core";
import Decimal from "decimal.js";
import { LoggerService } from "./logger.service";
import { TableId, CloudProvider } from "../models/vcloud-api";
import { Csp, Page, Section, DeploymentStage, DetailLevel, JobRole } from "../models/vcloudsmart";
import { Currency, CurrencyId } from "../models/currency";
import { FormattedValue } from "../models/value-formatting";
import { ATTR_REGEX, EMPTY_BYTES, EMPTY_VAL, SI_SIZES, SI_UNIT_MAP } from "../constant/value-formatting";
import { UserPermissions } from "../models/tenancies";
import { currencyOptions } from "../constant/currency";
import {
  awsDefinitions,
  azureDefinitions,
  drccDefinitions,
  gcpDefinitions,
  markdownConverter,
  ociDefinitions,
} from "../constant/definitions";
import { environment } from "src/environments/environment";
import { Definition } from "../models/definitions";
import { CSP } from "../models/csp-api";
import {
  REGEX_CAMEL_CASE,
  REGEX_POINTLESS_NAME,
  transformLegacyPageName,
  transformLegacySectionName,
} from "../constant/validation";
import { FeatureTogglesService } from "./feature-toggles.service";
import { MAX_API_RESPONSE_LIMIT, ROWS_PER_PAGE } from "../constant/vcloud-api";
import Swal from "sweetalert2";
import { CustomReport, CustomVCSReport, ReportShare } from "../models/report-sharing";
import { IconPack } from "../models/icons";
import { JOB_ROLES } from "../constant/finops-founation";

@Injectable({
  providedIn: "root",
})
export class LookerStudioService {
  constructor(private log: LoggerService, private featureToggles: FeatureTogglesService) {}

  public parseAttribute(attr: string): { table?: TableId; attribute: string } {
    const groups = ATTR_REGEX.exec(attr)?.groups;
    return {
      attribute: groups?.rest ?? attr,
      table: (groups?.table ?? undefined) as TableId | undefined,
    };
  }

  public getApiLimitAndPagesToLoad(
    frontEndTablePageSize: number,
    pageNumber: number,
    lastLoadedPage: number,
    backendTableRowsPerFrontEndTableRow: number = 1
  ): { frontEndTablePagesToLoad: number; backEndTableRowsToLoad: number } | undefined {
    const frontEndTablePagesToLoad: number = Math.floor(
      pageNumber - lastLoadedPage + ROWS_PER_PAGE / frontEndTablePageSize / backendTableRowsPerFrontEndTableRow
    );

    const backEndTableRowsToLoad: number = Math.floor(
      frontEndTablePageSize * frontEndTablePagesToLoad * backendTableRowsPerFrontEndTableRow
    );

    if (backEndTableRowsToLoad > MAX_API_RESPONSE_LIMIT) {
      Swal.fire({
        title: "Too Many Records",
        icon: "warning",
        text: `Your query would fetch too many (${backEndTableRowsToLoad}) rows.`,
        confirmButtonColor: "#E60000",
        allowOutsideClick: false,
        showCancelButton: false,
        showCloseButton: false,
        showConfirmButton: true,
        confirmButtonText: "OK",
      });
      return undefined;
    } else {
      return { backEndTableRowsToLoad, frontEndTablePagesToLoad };
    }
  }

  public prettifyAttribute(attribute: string): string {
    if (!attribute) {
      return "";
    }

    if (attribute.includes("line_item_unblended_cost")) {
      attribute = "Actual Cost";
    } else if (attribute.startsWith("line_item_")) {
      attribute = attribute.slice("line_item_".length, attribute.length);
    } else if (attribute.startsWith("billing_usage_")) {
      attribute = attribute.slice("billing_usage_".length, attribute.length);
    }
    const s = this.parseAttribute(attribute)
      .attribute.split("_")
      .map((w) => w.slice(0, 1).toUpperCase() + w.slice(1))
      .filter(Boolean)
      .join(" ")
      .replace(REGEX_CAMEL_CASE, "$1 $2")
      .replace(REGEX_POINTLESS_NAME, "$1");
    return s.endsWith(" Id") || s.endsWith(" id") ? `${s.slice(0, s.length - " id".length)} ID` : s;
  }

  public formatNumber(value: number | null | undefined, decimalPlaces = 2): FormattedValue {
    if (value === 0 || value === null || value === undefined) {
      return EMPTY_VAL;
    } else {
      const absValue = Math.abs(value);
      const found = SI_UNIT_MAP.find(({ threshold }) => absValue >= threshold);
      if (found) {
        const decimal = new Decimal(value).dividedBy(found.threshold).toDecimalPlaces(decimalPlaces);
        const n = decimal.toNumber();
        const suffix = found.suffix;
        return {
          fmt: `${n}${suffix}`,
          unit: suffix,
          decimal,
          value: n,
        };
      } else {
        return {
          fmt: value.toString(),
          unit: "",
          decimal: new Decimal(value),
          value: value,
        };
      }
    }
  }

  public formatBytes(bytes: number | null | undefined, decimals = 2): FormattedValue {
    if (bytes === undefined || bytes === null || bytes === 0) {
      return EMPTY_BYTES;
    } else {
      const base = new Decimal(Math.log(bytes)).dividedBy(Math.log(1024)).floor().toNumber();
      const power = new Decimal(1024).pow(base);
      const decimal = new Decimal(bytes).dividedBy(power).toDecimalPlaces(decimals);
      const n = decimal.toNumber();
      return {
        fmt: `${n}${SI_SIZES[base]}`,
        decimal,
        unit: SI_SIZES[base],
        value: n,
      };
    }
  }

  public formatBytesSI(bytes: number | null | undefined): FormattedValue {
    if (bytes === undefined || bytes === null || bytes === 0) {
      return EMPTY_BYTES;
    } else {
      const base = 10;
      for (let idx = SI_SIZES.length - 1; idx >= 0; idx--) {
        const value = bytes / base ** (idx * 3);
        if (value >= 1 || idx === 0) {
          const unit = [...SI_SIZES].find((_size, i) => i === idx).replace("i", "");
          return {
            fmt: `${new Decimal(value).toDecimalPlaces(2)}${unit}`,
            decimal: new Decimal(value).toDecimalPlaces(2),
            unit: unit,
            value,
          };
        }
      }
    }
  }

  private definitionsIndexed = {
    aws: Object.fromEntries(awsDefinitions.map((d) => [d.term, d])),
    azure: Object.fromEntries(azureDefinitions.map((d) => [d.term, d])),
    gcp: Object.fromEntries(gcpDefinitions.map((d) => [d.term, d])),
    oci: Object.fromEntries(ociDefinitions.map((d) => [d.term, d])),
    drcc: Object.fromEntries(drccDefinitions.map((d) => [d.term, d])),
  };

  private markdownCache = {};

  public getDefinition(csp: CSP, term: string): Definition {
    const d = (this.definitionsIndexed[csp] || {})[term];
    if (d) {
      const maybeCached = (this.markdownCache[csp] || {})[term];
      if (maybeCached) {
        return { ...d, definition: maybeCached };
      } else {
        if (!(csp in this.markdownCache)) {
          this.markdownCache[csp] = {};
        }
        const markdown = markdownConverter.makeMarkdown(d.definition);
        this.markdownCache[csp][term] = markdown;
        return { ...d, definition: markdown };
      }
    }
  }

  public getVCSConfigV2(currentTenant: string | undefined, user: UserPermissions, vcsConfig?: Array<Csp>): Array<Csp> {
    const configList = vcsConfig ?? this.getVCSConfig(currentTenant, user);

    return configList.map((csp) => {
      return {
        ...csp,
        sections: csp.sections.map((section) => {
          return {
            ...section,
            title: transformLegacySectionName(section.title),
            pages: section.pages.map((page) => {
              return {
                ...page,
                title: transformLegacySectionName(page.title),
              };
            }),
          };
        }),
      };
    });
  }

  public getVCSConfig(
    currentTenant: string,
    userInfo: UserPermissions,
    addRecent = false,
    customReports: CustomReport[] = undefined
  ): Array<Csp> {
    if (customReports === undefined) {
      customReports = [];
    }
    const result: Csp[] = [
      {
        id: "aws",
        title: "Amazon Web Services",
        icon: "/assets/icons-home/aws.svg",
        termsAndDefinitions: awsDefinitions,
        sections: [
          {
            title: "Cost & Billing",
            description: "Reports related to cost & billing of resources, services, accounts etc. over time.",
            pages: [
              {
                title: "Overview",
                isMigrated: true,
                iconName: "calendar",
                iconPack: "source-mid-render-light-icons",
                description: "Summary of costs over time, top cost contributors & cost grouped by service.",
                detailLevel: "LOW",
                jobRoles: ["Finance", "Engineering", "Leadership", "FinOps"],
                target:
                  "https://lookerstudio.google.com/embed/reporting/fa865367-b6f0-48b2-8009-d8064ed075c4/page/CCynB",
                styles: {
                  minHeight: "350vh",
                },
              },
              {
                title: "Charge Analysis (SAMS)",
                isMigrated: true,
                iconName: "pie-chart",
                isHidden: currentTenant !== "vodafone",
                iconPack: "source-mid-render-light-icons",
                detailLevel: "HIGH",
                jobRoles: ["Finance", "Leadership"],
                description:
                  "The report focuses on PCS & VPC charges added on top of the AWS Actual Cost. Use this report to understand internal charges.",
                isDisabled: currentTenant !== "vodafone",
                target:
                  "https://lookerstudio.google.com/embed/reporting/fa865367-b6f0-48b2-8009-d8064ed075c4/page/p_snh8j0lwvc",
                styles: {
                  minHeight: "270vh",
                },
              },
              {
                title: "Cost Analysis",
                isMigrated: true,
                detailLevel: "HIGH",
                jobRoles: ["Leadership", "Engineering", "Finance", "FinOps"],
                description: "Analysis of cost by various attributes such as: Account, Environment, Organisation etc.",
                iconName: "chart-bar",
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/fa865367-b6f0-48b2-8009-d8064ed075c4/page/p_l4s4ohmvuc",
                styles: {
                  minHeight: "350vh",
                },
              },
              {
                title: "Data Transfer Analysis",
                isMigrated: true,
                stage: "NEW",
                detailLevel: "HIGH",
                jobRoles: ["Engineering", "FinOps", "Operations"],
                description: "Analysis of data transfer (ingress/egress) costs",
                iconName: "network-tower",
                iconPack: "source-mid-render-light-icons",
              },
              {
                title: "Container Cost Analysis",
                isMigrated: true,
                description: "Analysis of the spend on containerised workloads (ECS, EKS, ECR etc.)",
                iconName: "box",
                iconPack: "source-mid-render-light-icons",
                detailLevel: "HIGH",
                jobRoles: ["Engineering", "Operations", "FinOps"],
              },
              {
                title: "Cost by Resource Tags",
                iconName: "price-tag",
                iconPack: "source-mid-render-light-icons",
                detailLevel: "HIGH",
                jobRoles: ["Engineering", "Operations"],
                description:
                  "The report displays resource level costs by the resource Tag configured in your Master Account.",
                isMigrated: true,
                target:
                  "https://lookerstudio.google.com/embed/reporting/fa865367-b6f0-48b2-8009-d8064ed075c4/page/p_rokf0o2lwc",
                styles: {
                  minHeight: "310vh",
                },
              },
              {
                title: "Executive Dashboard",
                description: "High level overview of change in cost as well as analysis of Compute & Storage costs.",
                detailLevel: "LOW",
                jobRoles: ["Leadership", "FinOps"],
                isMigrated: true,
                iconName: "business",
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/3a4dc621-5ba3-4cb9-a67e-ba75feeba30e/page/PmitC",
                styles: {
                  minHeight: "250vh",
                },
              },
              /* {
                title: "Cost per Product",
                description: "Usage cost sliced by AWS service (e.g. Lambda, S3...)",
                isMigrated: true,
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                iconName: "settings",
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/fa865367-b6f0-48b2-8009-d8064ed075c4/page/p_w2zh7v0lwc",
                styles: {
                  minHeight: "350vh",
                },
              }, */
              {
                title: "Cost Governance",
                isMigrated: true,
                description: "Aggregated cost optimisation dashboard",
                detailLevel: "LOW",
                jobRoles: ["Operations", "Engineering", "FinOps"],
                iconName: "community",
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/c919b1ad-d0c2-42e3-92dd-58803dfaba69/page/p_g9e396qj9c",
                styles: {
                  minHeight: "240vh",
                },
              },
              {
                title: "Cost per Master Account (Breakdown)",
                isMigrated: true,
                detailLevel: "LOW",
                jobRoles: ["Leadership", "Finance"],
                iconName: "savings",
                iconPack: "source-mid-render-light-icons",
                description: "Cost per AWS Master Account presented in a tabular format",
                target:
                  "https://lookerstudio.google.com/embed/reporting/fa865367-b6f0-48b2-8009-d8064ed075c4/page/p_97gzd8zlwc",
                styles: {
                  minHeight: "350vh",
                },
              },
              {
                title: "Forecasts",
                description: "See the predicted future costs of your accounts",
                detailLevel: "LOW",
                jobRoles: ["Leadership", "Finance", "FinOps"],
                iconName: "chart-line",
                iconPack: "source-mid-render-light-icons",
                isMigrated: true,
                target:
                  "https://lookerstudio.google.com/embed/reporting/fa865367-b6f0-48b2-8009-d8064ed075c4/page/p_adrtmf8l6c",
                styles: {
                  minHeight: "320vh",
                },
              },
              {
                title: "AWS Cost Anomaly Detection",
                description: "See the anomalies in your AWS accounts daily spending",
                detailLevel: "LOW",
                jobRoles: ["Leadership", "Finance", "FinOps"],
                isMigrated: true,
                isDisabled: !this.featureToggles.isFeatureEnabled(userInfo, "anomaly-detection"),
                iconName: "anomaly_detection",
                iconPack: "custom-icons",
              },
            ],
          },
          {
            title: "Budgets",
            isDisabled: currentTenant !== "vodafone",
            pages: [
              {
                description:
                  "This service is being decommissioned. Please refer to the new service, Budgets & Alerts, for future use",
                isMigrated: true,
                iconName: "briefcase",
                detailLevel: "LOW",
                jobRoles: ["Leadership", "Engineering", "Operations", "FinOps"],
                iconPack: "source-mid-render-light-icons",
                title: "Budgets Overview",
                target:
                  "https://lookerstudio.google.com/embed/reporting/f4434adb-885a-4e31-8c1b-5ad0c5a56ded/page/p_f2ur51rlwc",
                styles: {
                  minHeight: "310vh",
                },
              },
              {
                isMigrated: true,
                isDisabled: true,
                title: "Budgets Variance",
                detailLevel: "LOW",
                jobRoles: ["Leadership", "Engineering", "Operations", "FinOps"],
                iconName: "make-payment",
                description:
                  "This service is being decommissioned. Please refer to the new service, Budgets & Alerts, for future use",
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/f4434adb-885a-4e31-8c1b-5ad0c5a56ded/page/p_rb20unslwc",
                styles: {
                  minHeight: "350vh",
                },
              },
            ],
          },
          {
            title: "Asset Inventory",
            description:
              "Aggregated (multi-account and multi org.) asset inventories for various services & resource types. Use it to find resources and for security & compliance (CSB).",
            pages: [
              {
                title: "Inventory",
                iconName: "book",
                iconPack: "source-mid-render-light-icons",
                description:
                  "Aggregated (multi account, multi org.) view of all AWS assets. Search, filter, find, export..",
                isMigrated: true,
                detailLevel: "LOW",
                jobRoles: ["Engineering", "Operations", "Security", "FinOps"],
                target:
                  "https://lookerstudio.google.com/embed/reporting/1891a43e-6029-4f20-9336-464627d2a67c/page/p_i4lvk6qc2c",
                styles: {
                  minHeight: "450vh",
                },
              },
              {
                title: "EC2 Instances",
                iconName: "e-sim",
                iconPack: "source-mid-render-light-icons",
                detailLevel: "HIGH",
                jobRoles: ["Engineering", "Operations", "Security"],
                description:
                  "Aggregated (multi account, multi org.) view of all EC2 instances. Search, filter, find, export..",
                isMigrated: true,
                target:
                  "https://lookerstudio.google.com/embed/reporting/5ad2cf38-3700-4749-b188-f713b23fa203/page/p_ub44yu2ipc",
                styles: {
                  minHeight: "350vh",
                },
              },
              // {
              //   title: "EC2 Instances (Account)",
              //   iconName: "users",
              //   iconPack: "source-mid-render-light-icons",
              //   target:
              //     "https://lookerstudio.google.com/embed/reporting/5ad2cf38-3700-4749-b188-f713b23fa203/page/p_hxilvxjfpc",
              //   styles: {
              //     minHeight: "280vh",
              //   },
              //   isMigrated: true,
              //   isHidden: currentTenant !== "vodafone",
              // },
              // {
              //   title: "EC2 Instances (Images)",
              //   iconName: "dashboard",
              //   iconPack: "source-mid-render-light-icons",
              //   target:
              //     "https://lookerstudio.google.com/embed/reporting/5ad2cf38-3700-4749-b188-f713b23fa203/page/p_9ocghgogpc",
              //   styles: {
              //     minHeight: "280vh",
              //   },
              //   isMigrated: true,
              //   isHidden: currentTenant !== "vodafone",
              // },
              // {
              //   title: "EC2 Instances (Monitoring)",
              //   target:
              //     "https://lookerstudio.google.com/embed/reporting/5ad2cf38-3700-4749-b188-f713b23fa203/page/p_d22avbn6oc",
              //   styles: {
              //     minHeight: "310vh",
              //   },
              //   isMigrated: true,
              //   iconName: "security-camera",
              //   iconPack: "source-mid-render-light-icons",
              //   isHidden: currentTenant !== "vodafone",
              // },
              // {
              //   title: "EC2 Instances (State)",
              //   iconName: "e-sim",
              //   iconPack: "source-mid-render-light-icons",
              //   target:
              //     "https://lookerstudio.google.com/embed/reporting/5ad2cf38-3700-4749-b188-f713b23fa203/page/p_xmlahyogpc",
              //   styles: {
              //     minHeight: "310vh",
              //   },
              //   isMigrated: true,
              //   isHidden: currentTenant !== "vodafone",
              // },
              // {
              //   title: "EC2 Instances (Zone)",
              //   target:
              //     "https://lookerstudio.google.com/embed/reporting/5ad2cf38-3700-4749-b188-f713b23fa203/page/p_z8hze6m6oc",
              //   styles: {
              //     minHeight: "300vh",
              //   },
              //   iconName: "location",
              //   iconPack: "source-mid-render-light-icons",
              //   isMigrated: true,
              //   isHidden: currentTenant !== "vodafone",
              // },
              {
                title: "Terminated EC2 Instances",
                isMigrated: true,
                detailLevel: "HIGH",
                jobRoles: ["Engineering", "Operations", "Security"],
                iconName: "cancel",
                iconPack: "source-mid-render-light-icons",
                description:
                  "Aggregated (multi account, multi org.) view of all terminated EC2 instances. Search, filter, find, export..",
                target:
                  "https://lookerstudio.google.com/embed/reporting/fa865367-b6f0-48b2-8009-d8064ed075c4/page/p_69b0zl2ppc",
                styles: {
                  minHeight: "330vh",
                },
              },
              {
                title: "RDS Databases",
                iconName: "sme",
                detailLevel: "LOW",
                jobRoles: ["Engineering", "Operations", "Security", "Leadership"],
                iconPack: "source-mid-render-light-icons",
                description:
                  "Aggregated (multi account, multi org.) view of all RDS databases (MySQL, Postgres etc.). Search, filter, find, export..",
                isMigrated: true,
                target:
                  "https://lookerstudio.google.com/embed/reporting/fa865367-b6f0-48b2-8009-d8064ed075c4/page/p_qls2pd305c",
                styles: {
                  minHeight: "350vh",
                },
              },
              {
                title: "S3 Storage Buckets",
                iconName: "iot",
                iconPack: "source-mid-render-light-icons",
                detailLevel: "LOW",
                jobRoles: ["Engineering", "Operations", "Security"],
                isMigrated: true,
                description:
                  "Aggregated (multi account, multi org.) view of all S3 buckets. Search, filter, find, export..",
                isHidden: currentTenant !== "vodafone",
                target:
                  "https://lookerstudio.google.com/embed/reporting/fa865367-b6f0-48b2-8009-d8064ed075c4/page/p_kgi7zhyc2c",
                styles: {
                  minHeight: "350vh",
                },
              },
              {
                title: "Compute Dashboard",
                description: "",
                isMigrated: true,
                detailLevel: "LOW",
                jobRoles: ["Leadership", "Engineering", "Operations", "Security"],
                iconName: "dashboard",
                iconPack: "source-mid-render-light-icons",
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                target: "",
                styles: {
                  minHeight: "250vh",
                },
              },
            ],
          },
          {
            title: "Cloud Recommendations",
            description:
              "Reports that focus on cost optimisation and recommendations such as changing instance type, removing unused resources, purchasing a commitment to reduce cost etc.",
            pages: [
              {
                isMigrated: true,
                description: "See the detailed cost of compute services: EC2, Lambda & ECS.",
                title: "Cost by On-Demand Compute",
                jobRoles: ["Engineering", "Operations", "FinOps", "Security"],
                detailLevel: "HIGH",
                iconName: "e-sim",
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/fa865367-b6f0-48b2-8009-d8064ed075c4/page/p_urtjlpnv0c",
                styles: {
                  minHeight: "310vh",
                },
              },
              {
                title: "EC2 Instance Rightsizing",
                isMigrated: true,
                description: "Analyse which instances are overprovisioned and can be resized to save money.",
                iconName: "control-panel",
                detailLevel: "HIGH",
                jobRoles: ["Engineering", "Operations", "FinOps"],
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/fa865367-b6f0-48b2-8009-d8064ed075c4/page/p_x59xal4b6c",
                styles: {
                  minHeight: "400vh",
                },
              },
              {
                title: "Savings Plans",
                isMigrated: true,
                iconName: "celebrate",
                iconPack: "source-mid-render-light-icons",
                detailLevel: "HIGH",
                jobRoles: ["Engineering", "Operations", "FinOps"],
                description:
                  "Savings plans are commitments on the usage of compute (EC2, ECS, Lambda) resources. Click to see recommendations.",
                target:
                  "https://lookerstudio.google.com/embed/reporting/fa865367-b6f0-48b2-8009-d8064ed075c4/page/p_6b99r13b6c",
                styles: {
                  minHeight: "380vh",
                },
              },
              {
                title: "Underutilized EBS Volumes",
                description: "Analyse which storage disks can be removed and/or are overprovisioned.",
                iconName: "prepare",
                detailLevel: "HIGH",
                jobRoles: ["Engineering", "Operations", "FinOps"],
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/fa865367-b6f0-48b2-8009-d8064ed075c4/page/p_4vedbb550c",
                styles: {
                  minHeight: "350vh",
                },
                isMigrated: true,
                isHidden: currentTenant !== "vodafone",
              },
              {
                isMigrated: true,
                title: "Unassociated IP Addresses",
                iconName: "gravity",
                detailLevel: "HIGH",
                jobRoles: ["Engineering", "Operations", "FinOps"],
                description: "Analyse which IP addresses can be removed to save money.",
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/fa865367-b6f0-48b2-8009-d8064ed075c4/page/p_ni83ha550c",
                styles: {
                  minHeight: "350vh",
                },
              },
              {
                title: "Reserved Instances Recommendations",
                isMigrated: true,
                iconName: "deals",
                detailLevel: "HIGH",
                jobRoles: ["Engineering", "Operations", "FinOps"],
                iconPack: "source-mid-render-light-icons",
                description:
                  "Reserved instances are commitments on the usage of specific EC2 instance types. A newer, more flexible version of such commitments are 'Savings Plans'. Click to see RI recommendations.",
                target:
                  "https://lookerstudio.google.com/embed/reporting/fa865367-b6f0-48b2-8009-d8064ed075c4/page/p_w4jm4bv7uc",
                styles: {
                  minHeight: "350vh",
                },
              },
              {
                title: "EKS Recommendations",
                isMigrated: true,
                iconName: "diagnostics",
                iconPack: "source-mid-render-light-icons",
                detailLevel: "HIGH",
                jobRoles: ["Engineering", "Operations", "FinOps"],
                description: "See recommendations related to compute resources uses by your EKS clusters.",
                target:
                  "https://lookerstudio.google.com/embed/reporting/fa865367-b6f0-48b2-8009-d8064ed075c4/page/p_n2mb0scr8c",
                styles: {
                  minHeight: "250vh",
                },
              },
              {
                title: "Cost Savings by PCS Scheduler",
                isMigrated: true,
                iconName: "clock",
                iconPack: "source-mid-render-light-icons",
                detailLevel: "HIGH",
                jobRoles: ["Engineering", "Operations", "FinOps"],
                description:
                  "This report shows you the cost reduction achieved by the PCS scheduler. To opt in click on Services > AWS > Scheduler.",
                target:
                  "https://lookerstudio.google.com/embed/reporting/fa865367-b6f0-48b2-8009-d8064ed075c4/page/p_hoqvdq7b6c",
                styles: {
                  minHeight: "300vh",
                },
                isHidden: currentTenant !== "vodafone",
              },
              {
                title: "Underutilized Endpoints",
                isMigrated: true,
                iconName: "international",
                detailLevel: "HIGH",
                jobRoles: ["Engineering", "Operations", "FinOps"],
                iconPack: "source-mid-render-light-icons",
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                description: "",
                target: "",
                styles: {
                  minHeight: "300vh",
                },
                isHidden: currentTenant !== "vodafone",
              },
            ],
          },
          //           {
          //             title: "Other",
          //             isHidden: currentTenant !== "vodafone",
          //             pages: [
          //               // {
          //               //   title: "Image Report I",
          //               //   isMigrated: false,
          //               //   iconName: "dashboard",
          //               //   iconPack: "source-mid-render-light-icons",
          //               //   isHidden: currentTenant !== "vodafone",
          //               //   target:
          //               //     "https://lookerstudio.google.com/embed/reporting/1e9383fe-a1e9-468b-a9fc-5e0cb2081c1e/page/p_dainx9zdtc",
          //               //   styles: {
          //               //     minHeight: "370vh",
          //               //   },
          //               // },
          //               {
          //                 title: "Image Analysis",
          //                 isMigrated: false,
          //                 iconName: "dashboard",
          //                 iconPack: "source-mid-render-light-icons",
          //                 isHidden: currentTenant !== "vodafone",
          //                 target:
          //                   "https://lookerstudio.google.com/embed/reporting/1e9383fe-a1e9-468b-a9fc-5e0cb2081c1e/page/p_esiip40dtc",
          //                 styles: {
          //                   minHeight: "350vh",
          //                 },
          //               },
          //             ],
          //           },
        ],
      },
      {
        id: "gcp",
        title: "Google Cloud Platform",
        isDisabled: currentTenant !== "vodafone",
        icon: "/assets/icons-home/gcp.svg",
        termsAndDefinitions: gcpDefinitions,
        sections: [
          {
            title: "Cost & Billing",
            description: "Reports related to cost & billing of resources, services, accounts etc. over time.",
            pages: [
              {
                title: "Overview",
                isMigrated: true,
                iconName: "calendar",
                iconPack: "source-mid-render-light-icons",
                detailLevel: "LOW",
                jobRoles: ["Finance", "Engineering", "Leadership", "FinOps"],
                description: "Summary of costs over time, top cost contributors & budgets.",
                target:
                  "https://lookerstudio.google.com/embed/reporting/c716631c-4608-4193-a53d-59f9410932ad/page/p_wq2fy4g1oc",
                styles: {
                  minHeight: "400vh",
                },
              },
              {
                isMigrated: true,
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                iconName: "pie-chart",
                description: "INTERNAL and EXTERNAL charge analysis",
                iconPack: "source-mid-render-light-icons",
                title: "Charge Analysis (SAMS)",
                detailLevel: "HIGH",
                jobRoles: ["Finance", "Leadership"],
                target:
                  "https://lookerstudio.google.com/embed/reporting/c716631c-4608-4193-a53d-59f9410932ad/page/p_kpf8q93ooc",
                styles: {
                  minHeight: "350vh",
                },
              },
              {
                title: "Cost Analysis",
                description: "Analysis of cost by various attributes such as: Service, Project, Programme etc.",
                iconName: "chart-bar",
                iconPack: "source-mid-render-light-icons",
                isMigrated: true,
                detailLevel: "HIGH",
                jobRoles: ["Leadership", "Engineering", "Finance", "FinOps"],
                target:
                  "https://lookerstudio.google.com/embed/reporting/c716631c-4608-4193-a53d-59f9410932ad/page/p_4dbftpvv3c",
                styles: {
                  minHeight: "350vh",
                },
              },
              {
                title: "Executive Dashboard",
                isMigrated: true,
                description: "High level overview of change in cost as well as analysis of Compute & Storage costs.",
                iconName: "business",
                detailLevel: "LOW",
                jobRoles: ["Leadership", "FinOps"],
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/8a6a9a5b-b94a-478d-9037-e645152d8109/page/p_7bi7wyitxc",
                styles: {
                  minHeight: "200vh",
                },
              },
              {
                title: "Cost by Label",
                isMigrated: true,
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                iconName: "price-tag",
                detailLevel: "HIGH",
                jobRoles: ["Engineering", "Operations"],
                iconPack: "source-mid-render-light-icons",
                description:
                  "The report displays resource level costs by the resource label configured on the resource.",
                target:
                  "https://lookerstudio.google.com/embed/reporting/d973f81c-3708-4eff-82d5-9d1a2a380012/page/p_stgm8n6q5c",
                styles: {
                  minHeight: "250vh",
                },
              },
              {
                title: "Other Org and Support Charges",
                isMigrated: true,
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                iconName: "community",
                detailLevel: "LOW",
                jobRoles: ["Leadership", "FinOps", "Finance"],
                description: "Analysis of cost by other organisation and support charges",
                iconPack: "source-mid-render-light-icons",
                isHidden: currentTenant !== "vodafone",
                target:
                  "https://lookerstudio.google.com/embed/reporting/c716631c-4608-4193-a53d-59f9410932ad/page/p_i9ialmg7tc",
                styles: {
                  minHeight: "300vh",
                },
              },
              {
                title: "Cost by Billing Account",
                isMigrated: true,
                iconName: "bill",
                iconPack: "source-mid-render-light-icons",
                detailLevel: "LOW",
                jobRoles: ["Leadership", "Finance"],
                description:
                  "In GCP billing happens at the billing account level. Each billing account is associated to multiple GCP projects. Use this report to see the split of cost by billing account.",
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                target:
                  "https://lookerstudio.google.com/embed/reporting/c716631c-4608-4193-a53d-59f9410932ad/page/p_zy4q4fybuc",
                styles: {
                  minHeight: "300vh",
                },
              },
              {
                title: "Cost Breakdown",
                iconName: "savings",
                iconPack: "source-mid-render-light-icons",
                detailLevel: "LOW",
                jobRoles: ["Leadership", "Finance"],
                isMigrated: true,
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                description: "Split of cost into different categories (Actual Cost, Credits, etc.)",
                target:
                  "https://lookerstudio.google.com/embed/reporting/c716631c-4608-4193-a53d-59f9410932ad/page/ekPWC",
                styles: {
                  minHeight: "180vh",
                },
              },
              {
                title: "Cost Governance",
                isMigrated: true,
                description: "Aggregated cost optimisation dashboard",
                iconName: "community",
                jobRoles: ["Engineering", "Operations", "FinOps"],
                detailLevel: "LOW",
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/c919b1ad-d0c2-42e3-92dd-58803dfaba69/page/p_g9e396qj9c",
                styles: {
                  minHeight: "240vh",
                },
              },
              {
                title: "Cost by Compute Instance Runtime",
                description: "Analyse the cost consumption of your Compute Instance virtual machines",
                iconName: "e-sim",
                iconPack: "source-mid-render-light-icons",
                jobRoles: ["Engineering", "Operations", "FinOps"],
                detailLevel: "HIGH",
                isMigrated: true,
                target:
                  "https://lookerstudio.google.com/embed/reporting/c716631c-4608-4193-a53d-59f9410932ad/page/p_92raj9302c",
                styles: {
                  minHeight: "220vh",
                },
              },
              {
                title: "Forecasts",
                description: "See the predicted future costs of your projects",
                iconName: "chart-line",
                iconPack: "source-mid-render-light-icons",
                detailLevel: "LOW",
                jobRoles: ["Leadership", "Finance", "FinOps"],
                isMigrated: true,
                styles: {
                  minHeight: "320vh",
                },
              },
              {
                title: "BigQuery Cost Analysis",
                isMigrated: true,
                iconName: "sme",
                detailLevel: "HIGH",
                jobRoles: ["Engineering", "Operations"],
                iconPack: "source-mid-render-light-icons",
                description:
                  "Analyse the cost of your BigQuery queries (cost per query, duration of queries, trend over time...)",
                isHidden: currentTenant !== "vodafone",
                target:
                  "https://lookerstudio.google.com/embed/reporting/c716631c-4608-4193-a53d-59f9410932ad/page/IdqeB",
                styles: {
                  minHeight: "240vh",
                },
              },
            ],
          },
          {
            title: "Cloud Recommendations",
            description:
              "Reports that focus on cost optimisation and recommendations such as changing instance type, removing unused resources, purchasing a commitment to reduce cost etc.",
            isHidden: currentTenant !== "vodafone",
            pages: [
              {
                title: "Overview",
                isMigrated: true,
                jobRoles: ["Engineering", "Operations", "FinOps", "Security"],
                detailLevel: "LOW",
                iconName: "idea",
                iconPack: "source-mid-render-light-icons",
                isHidden: currentTenant !== "vodafone",
                target:
                  "https://lookerstudio.google.com/embed/reporting/c716631c-4608-4193-a53d-59f9410932ad/page/p_22ciwdqxgd",
                styles: {
                  minHeight: "450vh",
                },
              },
              {
                title: "Cost",
                isMigrated: true,
                jobRoles: ["Engineering", "FinOps"],
                detailLevel: "LOW",
                iconName: "savings",
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/c716631c-4608-4193-a53d-59f9410932ad/page/p_22ciwdqxgd",
                styles: {
                  minHeight: "400vh",
                },
              },
              {
                title: "Security",
                isMigrated: true,
                iconName: "padlock-close",
                jobRoles: ["Engineering", "Operations", "Security"],
                detailLevel: "LOW",
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/c716631c-4608-4193-a53d-59f9410932ad/page/p_22ciwdqxgd",
                styles: {
                  minHeight: "380vh",
                },
              },
              {
                title: "Performance",
                isMigrated: true,
                iconName: "control-panel",
                jobRoles: ["Engineering", "Operations", "FinOps"],
                detailLevel: "LOW",
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/c716631c-4608-4193-a53d-59f9410932ad/page/p_22ciwdqxgd",
                styles: {
                  minHeight: "380vh",
                },
              },
              {
                title: "Reliability",
                isMigrated: true,
                isHidden: currentTenant !== "vodafone",
                iconName: "sme",
                jobRoles: ["Engineering", "Operations"],
                detailLevel: "LOW",
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/c716631c-4608-4193-a53d-59f9410932ad/page/p_22ciwdqxgd",
                styles: {
                  minHeight: "380vh",
                },
              },
            ],
          },
          {
            title: "Budgets",
            isDisabled: currentTenant !== "vodafone",
            pages: [
              {
                title: "Budgets Overview",
                description:
                  "This service is being decommissioned. Please refer to the new service, Budgets & Alerts, for future use",
                isMigrated: true,
                detailLevel: "LOW",
                jobRoles: ["Leadership", "Engineering", "Operations", "FinOps"],
                iconName: "briefcase",
                iconPack: "source-mid-render-light-icons",
                isHidden: currentTenant !== "vodafone",
                target:
                  "https://lookerstudio.google.com/embed/reporting/c7b1ec35-88d7-4b79-9349-92d20f5f94cf/page/h9meB",
                styles: {
                  minHeight: "280vh",
                },
              },
              {
                title: "Budgets Variance",
                isDisabled: true,
                detailLevel: "LOW",
                jobRoles: ["Leadership", "Engineering", "Operations", "FinOps"],
                isMigrated: true,
                iconName: "make-payment",
                description:
                  "This service is being decommissioned. Please refer to the new service, Budgets & Alerts, for future use",
                iconPack: "source-mid-render-light-icons",
                isHidden: currentTenant !== "vodafone",
                target:
                  "https://lookerstudio.google.com/embed/reporting/c7b1ec35-88d7-4b79-9349-92d20f5f94cf/page/iyEmB",
                styles: {
                  minHeight: "310vh",
                },
              },
            ],
          },
          {
            title: "Asset Inventory",
            isHidden: currentTenant !== "vodafone",
            description:
              "Aggregated (multi-account and multi org.) asset inventories for various services & resource types. Use it to find resources and for security & compliance (CSB).",
            pages: [
              {
                title: "Overview",
                isMigrated: true,
                iconName: "book",
                iconPack: "source-mid-render-light-icons",
                description: "Aggregated (multi project) view of all GCP assets. Search, filter, find, export..",
                detailLevel: "LOW",
                jobRoles: ["Engineering", "Operations", "Security", "FinOps"],
                isHidden: currentTenant !== "vodafone",
                target:
                  "https://lookerstudio.google.com/embed/reporting/1057ab0a-ec5f-4274-be98-21b1a0037748/page/oypnC",
                styles: {
                  minHeight: "280vh",
                },
              },
              {
                title: "Compute Instances",
                isMigrated: true,
                description: "Aggregated (multi project) view of all Compute Instances. Search, filter, find, export..",
                iconName: "e-sim",
                detailLevel: "HIGH",
                jobRoles: ["Engineering", "Operations", "Security"],
                iconPack: "source-mid-render-light-icons",
                isHidden: currentTenant !== "vodafone",
                target:
                  "https://lookerstudio.google.com/embed/reporting/c716631c-4608-4193-a53d-59f9410932ad/page/p_8fhe3c105c",
                styles: {
                  minHeight: "250vh",
                },
              },
              //               {
              //                 title: "Projects",
              //                 isMigrated: false,
              //                 iconName: "calendar-month",
              //                 iconPack: "source-mid-render-light-icons",
              //                 isHidden: currentTenant !== "vodafone",
              //                 target:
              //                   "https://lookerstudio.google.com/embed/reporting/1057ab0a-ec5f-4274-be98-21b1a0037748/page/p_kn23i659sc",
              //                 styles: {
              //                   minHeight: "250vh",
              //                 },
              //               },
              //               {
              //                 title: "Location",
              //                 isMigrated: false,
              //                 iconName: "location",
              //                 iconPack: "source-mid-render-light-icons",
              //                 isHidden: currentTenant !== "vodafone",
              //                 target:
              //                   "https://lookerstudio.google.com/embed/reporting/1057ab0a-ec5f-4274-be98-21b1a0037748/page/p_9l8p7z5l5c",
              //                 styles: {
              //                   minHeight: "180vh",
              //                 },
              //               },
              {
                title: "Compute Dashboard",
                description: "",
                isMigrated: true,
                detailLevel: "LOW",
                jobRoles: ["Engineering", "Operations", "Security", "Leadership"],
                iconName: "dashboard",
                iconPack: "source-mid-render-light-icons",
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                target: "",
                styles: {
                  minHeight: "250vh",
                },
              },
            ],
          },
          // {
          //   title: "SAMS (VAMS)",
          //   isHidden: currentTenant !== "vodafone",
          //   description: "Financial reports",
          //   pages: [
          //     // {
          //     //   isMigrated: true,
          //     //   iconName: "pie-chart",
          //     //   description: "INTERNAL and EXTERNAL charge analysis",
          //     //   iconPack: "source-mid-render-light-icons",
          //     //   title: "SAMS Report",
          //     //   target:
          //     //     "https://lookerstudio.google.com/embed/reporting/c716631c-4608-4193-a53d-59f9410932ad/page/p_kpf8q93ooc",
          //     //   styles: {
          //     //     minHeight: "350vh",
          //     //   },
          //     // },
          //     {
          //       title: "Other Org and Support Charges",
          //       isMigrated: true,
          //       detailLevel: "HIGH",
          //       jobRoles: ["Finance", "Leadership", "FinOps"],
          //       iconName: "community",
          //       description: "Analysis of cost by other organisation and support charges",
          //       iconPack: "source-mid-render-light-icons",
          //       isHidden: currentTenant !== "vodafone",
          //       target:
          //         "https://lookerstudio.google.com/embed/reporting/c716631c-4608-4193-a53d-59f9410932ad/page/p_i9ialmg7tc",
          //       styles: {
          //         minHeight: "300vh",
          //       },
          //     },
          //     {
          //       title: "Billing Account",
          //       isMigrated: true,
          //       iconName: "bill",
          //       detailLevel: "HIGH",
          //       jobRoles: ["Finance", "Leadership", "FinOps"],
          //       iconPack: "source-mid-render-light-icons",
          //       description:
          //         "In GCP billing happens at the billing account level. Each billing account is associated to multiple GCP projects. Use this report to see the split of cost by billing account.",
          //       target:
          //         "https://lookerstudio.google.com/embed/reporting/c716631c-4608-4193-a53d-59f9410932ad/page/p_zy4q4fybuc",
          //       styles: {
          //         minHeight: "300vh",
          //       },
          //     },
          //   ],
          // },
          {
            title: "DataHub Cost Analysis",
            isHidden: currentTenant !== "vodafone",
            pages: [
              {
                title: "Overview",
                iconName: "public-sector",
                iconPack: "source-mid-render-light-icons",
                detailLevel: "HIGH",
                jobRoles: ["Leadership", "FinOps", "Engineering"],
                isMigrated: false,
                isHidden: currentTenant !== "vodafone",
                target:
                  "https://lookerstudio.google.com/embed/reporting/84f33885-2e6f-45be-9dbc-81ecbb587676/page/p_1zjx6w7ayc",
                styles: {
                  minHeight: "350vh",
                },
              },
              {
                title: "Actual Cost Distribution",
                isMigrated: false,
                iconName: "chart-line",
                iconPack: "source-mid-render-light-icons",
                detailLevel: "LOW",
                jobRoles: ["FinOps", "Engineering"],
                isHidden: currentTenant !== "vodafone",
                target:
                  "https://lookerstudio.google.com/embed/reporting/84f33885-2e6f-45be-9dbc-81ecbb587676/page/lJ3kB",
                styles: {
                  minHeight: "350vh",
                },
              },
              {
                title: "Storage Analysis",
                isMigrated: false,
                iconName: "sme",
                iconPack: "source-mid-render-light-icons",
                detailLevel: "HIGH",
                jobRoles: ["FinOps", "Engineering"],
                isHidden: currentTenant !== "vodafone",
                target:
                  "https://lookerstudio.google.com/embed/reporting/84f33885-2e6f-45be-9dbc-81ecbb587676/page/RuLiB",
                styles: {
                  minHeight: "230vh",
                },
              },
            ],
          },
          //           {
          //             title: "Other",
          //             isHidden: currentTenant !== "vodafone",
          //             pages: [
          //               // {
          //               //   title: "Image Report I",
          //               //   isMigrated: false,
          //               //   isHidden: currentTenant !== "vodafone",
          //               //   iconName: "dashboard",
          //               //   iconPack: "source-mid-render-light-icons",
          //               //   target:
          //               //     "https://lookerstudio.google.com/embed/reporting/1e9383fe-a1e9-468b-a9fc-5e0cb2081c1e/page/xU9MC",
          //               //   styles: {
          //               //     minHeight: "300vh",
          //               //   },
          //               // },
          //               {
          //                 title: "Image Analysis",
          //                 isMigrated: false,
          //                 isHidden: currentTenant !== "vodafone",
          //                 iconName: "dashboard",
          //                 iconPack: "source-mid-render-light-icons",
          //                 target:
          //                   "https://lookerstudio.google.com/embed/reporting/1e9383fe-a1e9-468b-a9fc-5e0cb2081c1e/page/xsbOC",
          //                 styles: {
          //                   minHeight: "350vh",
          //                 },
          //               },
          //             ],
          //           },
        ],
      },
      {
        id: "azure",
        title: "Microsoft Azure",
        icon: "/assets/icons-home/azure.svg",
        isDisabled: currentTenant !== "vodafone",
        termsAndDefinitions: azureDefinitions,
        sections: [
          {
            title: "Cost & Billing",
            description: "Reports related to cost & billing of resources, services, accounts etc. over time.",
            isHidden: currentTenant !== "vodafone",
            pages: [
              {
                title: "Overview",
                isMigrated: true,
                iconName: "calendar",
                iconPack: "source-mid-render-light-icons",
                isHidden: currentTenant !== "vodafone",
                detailLevel: "LOW",
                jobRoles: ["Finance", "Engineering", "Leadership", "FinOps"],
                target:
                  "https://lookerstudio.google.com/embed/reporting/e45719cf-1b4f-4d44-9614-8c6991276200/page/ZrmOC",
                styles: {
                  minHeight: "450vh",
                },
              },
              {
                title: "Cost Per Subscription",
                isMigrated: true,
                iconName: "dashboard",
                iconPack: "source-mid-render-light-icons",
                detailLevel: "HIGH",
                jobRoles: ["Leadership", "Engineering", "Finance", "FinOps"],
                isHidden: currentTenant !== "vodafone",
                target:
                  "https://lookerstudio.google.com/embed/reporting/e45719cf-1b4f-4d44-9614-8c6991276200/page/p_749taotiqc",
                styles: {
                  minHeight: "350vh",
                },
              },
              {
                title: "Cost Analysis",
                description: "Analysis of cost by various attributes such as: Service, Project, Programme etc.",
                isMigrated: true,
                detailLevel: "HIGH",
                jobRoles: ["Leadership", "Engineering", "Finance", "FinOps"],
                iconName: "chart-bar",
                iconPack: "source-mid-render-light-icons",
                isHidden: currentTenant !== "vodafone",
                target:
                  "https://lookerstudio.google.com/embed/reporting/e45719cf-1b4f-4d44-9614-8c6991276200/page/EUfOC",
                styles: {
                  minHeight: "380vh",
                },
              },
              {
                title: "Charge Analysis",
                description: "Analysis of cost by various attributes such as: Service, Project, Programme etc.",
                isMigrated: true,
                iconName: "chart-bar",
                detailLevel: "HIGH",
                jobRoles: ["Finance", "Leadership"],
                iconPack: "source-mid-render-light-icons",
                isHidden: currentTenant !== "vodafone",
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                target:
                  "https://lookerstudio.google.com/embed/reporting/e45719cf-1b4f-4d44-9614-8c6991276200/page/EUfOC",
                styles: {
                  minHeight: "380vh",
                },
              },
              {
                title: "Executive Dashboard",
                description: "High level overview of change in cost as well as analysis of Compute & Storage costs.",
                isMigrated: true,
                iconName: "business",
                detailLevel: "LOW",
                jobRoles: ["Leadership", "FinOps"],
                iconPack: "source-mid-render-light-icons",
                styles: {
                  minHeight: "250vh",
                },
              },
              {
                title: "Cost Breakdown",
                target:
                  "https://lookerstudio.google.com/embed/reporting/e45719cf-1b4f-4d44-9614-8c6991276200/page/p_05yxqhjjqc",
                styles: {
                  minHeight: "200vh",
                },
                isMigrated: false,
                iconName: "savings",
                detailLevel: "LOW",
                jobRoles: ["Leadership", "Finance"],
                iconPack: "source-mid-render-light-icons",
                isHidden: currentTenant !== "vodafone",
              },
              {
                title: "Resource Cost by Custom Tag",
                iconName: "price-tag",
                iconPack: "source-mid-render-light-icons",
                detailLevel: "HIGH",
                jobRoles: ["Engineering", "Operations"],
                isMigrated: true,
                target:
                  "https://lookerstudio.google.com/embed/reporting/e45719cf-1b4f-4d44-9614-8c6991276200/page/p_u75ytojjqc",
                styles: {
                  minHeight: "280vh",
                },
              },
            ],
          },
          {
            title: "Asset Inventory",
            isHidden: currentTenant !== "vodafone",
            description:
              "Aggregated (multi-account and multi org.) asset inventories for various services & resource types. Use it to find resources and for security & compliance (CSB).",
            pages: [
              {
                title: "Inventory",
                isMigrated: true,
                detailLevel: "LOW",
                jobRoles: ["Engineering", "Operations", "Security", "FinOps"],
                iconName: "book",
                iconPack: "source-mid-render-light-icons",
                isHidden: currentTenant !== "vodafone",
                target:
                  "https://lookerstudio.google.com/embed/reporting/8c3c801a-8b9a-4bb6-8970-1dbd3ebe5265/page/p_qigl30bwpc",
                styles: {
                  minHeight: "450vh",
                },
              },
              {
                iconName: "loan",
                iconPack: "source-mid-render-light-icons",
                title: "Subscriptions",
                isMigrated: true,
                detailLevel: "LOW",
                jobRoles: ["Engineering", "Operations", "Security"],
                isHidden: currentTenant !== "vodafone",
                target:
                  "https://lookerstudio.google.com/embed/reporting/8c3c801a-8b9a-4bb6-8970-1dbd3ebe5265/page/p_7k70km1htc",
                styles: {
                  minHeight: "350vh",
                },
              },
              {
                title: "Other",
                isMigrated: false,
                iconName: "placeholder",
                iconPack: "source-mid-render-light-icons",
                detailLevel: "HIGH",
                jobRoles: ["Engineering", "Operations", "Security"],
                isHidden: currentTenant !== "vodafone",
                target:
                  "https://lookerstudio.google.com/embed/reporting/8c3c801a-8b9a-4bb6-8970-1dbd3ebe5265/page/p_2ofqv1p1sc",
                styles: {
                  minHeight: "320vh",
                },
              },
            ],
          },
          {
            title: "SAMS (VAMS)",
            description: "Financial reports",
            isHidden: currentTenant !== "vodafone",
            pages: [
              {
                title: "SAMS Report",
                isMigrated: true,
                detailLevel: "HIGH",
                jobRoles: ["Finance", "Leadership"],
                iconName: "pie-chart",
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/e45719cf-1b4f-4d44-9614-8c6991276200/page/p_4c5y5qgiqc",
                styles: {
                  minHeight: "450vh",
                },
              },
            ],
          },
          {
            title: "Cloud Recommendations",
            description:
              "Reports that focus on cost optimisation and recommendations such as changing instance type, removing unused resources, purchasing a commitment to reduce cost etc.",
            isHidden: currentTenant !== "vodafone",
            pages: [
              {
                title: "Overview",
                isMigrated: true,
                iconName: "idea",
                iconPack: "source-mid-render-light-icons",
                jobRoles: ["Engineering", "Operations", "FinOps", "Security"],
                detailLevel: "LOW",
                isHidden: currentTenant !== "vodafone",
                target:
                  "https://lookerstudio.google.com/embed/reporting/e45719cf-1b4f-4d44-9614-8c6991276200/page/p_3zfc3ujjqc",
                styles: {
                  minHeight: "330vh",
                },
              },
              {
                title: "Security",
                isHidden: currentTenant !== "vodafone",
                isMigrated: true,
                iconName: "padlock-close",
                jobRoles: ["Engineering", "Operations", "Security"],
                detailLevel: "LOW",
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/e45719cf-1b4f-4d44-9614-8c6991276200/page/p_3zfc3ujjqc",
                styles: {
                  minHeight: "330vh",
                },
              },
              {
                title: "Operational Excellence",
                isMigrated: true,
                isHidden: currentTenant !== "vodafone",
                iconName: "padlock-close",
                jobRoles: ["Engineering", "Operations"],
                detailLevel: "LOW",
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/e45719cf-1b4f-4d44-9614-8c6991276200/page/p_3zfc3ujjqc",
                styles: {
                  minHeight: "330vh",
                },
              },
              {
                title: "Performance",
                isMigrated: true,
                isHidden: currentTenant !== "vodafone",
                iconName: "control-panel",
                jobRoles: ["Engineering", "Operations", "FinOps"],
                detailLevel: "LOW",
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/e45719cf-1b4f-4d44-9614-8c6991276200/page/p_3zfc3ujjqc",
                styles: {
                  minHeight: "330vh",
                },
              },
              {
                title: "High Availability",
                isMigrated: true,
                isHidden: currentTenant !== "vodafone",
                iconName: "sme",
                jobRoles: ["Engineering", "Operations"],
                detailLevel: "LOW",
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/e45719cf-1b4f-4d44-9614-8c6991276200/page/p_3zfc3ujjqc",
                styles: {
                  minHeight: "330vh",
                },
              },
              {
                title: "Purchased Reservations",
                isMigrated: true,
                isHidden: currentTenant !== "vodafone",
                iconName: "deals",
                jobRoles: ["Engineering", "Operations", "FinOps"],
                detailLevel: "LOW",
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/e45719cf-1b4f-4d44-9614-8c6991276200/page/p_coh5zo1eyc",
                styles: {
                  minHeight: "240vh",
                },
              },
            ],
          },
          {
            title: "Other",
            isHidden: currentTenant !== "vodafone",
            pages: [
              /* {
                title: "Commitment Analysis",
                iconName: "diagnostics",
                iconPack: "source-mid-render-light-icons",
                jobRoles: ["Engineering", "Operations", "FinOps"],
                detailLevel: "HIGH",
                isMigrated: false,
                isHidden: currentTenant !== "vodafone",
                target:
                  "https://lookerstudio.google.com/embed/reporting/e45719cf-1b4f-4d44-9614-8c6991276200/page/p_cue31sjjqc",
              }, */
              {
                title: "SKU Discount",
                iconName: "price-tag",
                iconPack: "source-mid-render-light-icons",
                isMigrated: true,
                jobRoles: ["Engineering", "Operations", "FinOps"],
                detailLevel: "HIGH",
                isHidden: currentTenant !== "vodafone",
                styles: {
                  minHeight: "450vh",
                },
                target:
                  "https://lookerstudio.google.com/embed/reporting/5bc3dd85-4d18-4c2f-9acd-291d97b92409/page/p_cue31sjjqc",
              },
            ],
          },
        ],
      },
      {
        id: "oci",
        title: "Oracle Cloud",
        icon: "/assets/icons-home/oracle.svg",
        isDisabled: currentTenant !== "vodafone",
        isHidden: false,
        termsAndDefinitions: ociDefinitions,
        sections: [
          {
            title: "Cost & Billing",
            description: "Reports related to cost & billing of resources, services, accounts etc. over time.",
            pages: [
              {
                title: "Overview",
                isMigrated: true,
                iconName: "calendar",
                detailLevel: "LOW",
                jobRoles: ["Finance", "Engineering", "Leadership", "FinOps"],
                iconPack: "source-mid-render-light-icons",
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                target:
                  "https://lookerstudio.google.com/embed/reporting/5e4c46ce-8e9d-4d39-a837-2993ef4cbeed/page/p_ota1z242vc",
                styles: {
                  minHeight: "450vh",
                },
              },
              {
                title: "Cost Analysis",
                isMigrated: true,
                iconName: "chart-bar",
                iconPack: "source-mid-render-light-icons",
                detailLevel: "HIGH",
                jobRoles: ["Leadership", "Engineering", "Finance", "FinOps"],
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                target:
                  "https://lookerstudio.google.com/embed/reporting/5e4c46ce-8e9d-4d39-a837-2993ef4cbeed/page/p_de6mkmf02c",
                styles: {
                  minHeight: "350vh",
                },
              },
            ],
          },
          {
            title: "Asset Inventory",
            description:
              "Aggregated (multi-account and multi org.) asset inventories for various services & resource types. Use it to find resources and for security & compliance (CSB).",
            pages: [
              {
                title: "Assets Overview",
                isMigrated: true,
                detailLevel: "LOW",
                jobRoles: ["Engineering", "Operations", "Security", "FinOps"],
                iconName: "book",
                iconPack: "source-mid-render-light-icons",
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                target:
                  "https://lookerstudio.google.com/embed/reporting/5e4c46ce-8e9d-4d39-a837-2993ef4cbeed/page/p_yqsoa9sd8c",
                styles: {
                  minHeight: "450vh",
                },
              },
              {
                title: "File Systems",
                isMigrated: true,
                iconName: "document-zip",
                detailLevel: "LOW",
                jobRoles: ["Engineering", "Operations", "Security"],
                iconPack: "source-mid-render-light-icons",
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                target:
                  "https://lookerstudio.google.com/embed/reporting/5e4c46ce-8e9d-4d39-a837-2993ef4cbeed/page/p_3yx6ahtd8c",
                styles: {
                  minHeight: "450vh",
                },
              },
              {
                title: "Virtual Networks",
                isMigrated: true,
                iconName: "network-tower",
                iconPack: "source-mid-render-light-icons",
                detailLevel: "HIGH",
                jobRoles: ["Engineering", "Operations", "Security"],
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                target:
                  "https://lookerstudio.google.com/embed/reporting/5e4c46ce-8e9d-4d39-a837-2993ef4cbeed/page/p_wn6ubhtd8c",
                styles: {
                  minHeight: "450vh",
                },
              },
              {
                title: "Storage Volumes",
                isMigrated: true,
                iconName: "usb-modem",
                iconPack: "source-mid-render-light-icons",
                detailLevel: "LOW",
                jobRoles: ["Engineering", "Operations", "Security"],
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                target:
                  "https://lookerstudio.google.com/embed/reporting/5e4c46ce-8e9d-4d39-a837-2993ef4cbeed/page/p_dz5l9gtd8c",
                styles: {
                  minHeight: "450vh",
                },
              },
              {
                title: "Compute Instances",
                isMigrated: true,
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                iconName: "e-sim",
                detailLevel: "LOW",
                jobRoles: ["Engineering", "Operations", "Security", "Leadership"],
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/5e4c46ce-8e9d-4d39-a837-2993ef4cbeed/page/p_z2o44gtd8c",
                styles: {
                  minHeight: "450vh",
                },
              },
              {
                title: "Database Systems",
                isMigrated: true,
                iconName: "sme",
                iconPack: "source-mid-render-light-icons",
                detailLevel: "LOW",
                jobRoles: ["Engineering", "Operations", "Security", "Leadership"],
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                target:
                  "https://lookerstudio.google.com/embed/reporting/5e4c46ce-8e9d-4d39-a837-2993ef4cbeed/page/p_mhu57gtd8c",
                styles: {
                  minHeight: "450vh",
                },
              },
              {
                title: "Buckets",
                isMigrated: true,
                iconName: "iot",
                iconPack: "source-mid-render-light-icons",
                detailLevel: "LOW",
                jobRoles: ["Engineering", "Operations", "Security"],
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                target:
                  "https://lookerstudio.google.com/embed/reporting/5e4c46ce-8e9d-4d39-a837-2993ef4cbeed/page/p_msibahtd8c",
                styles: {
                  minHeight: "450vh",
                },
              },
            ],
          },
          {
            title: "Recommendations",
            isHidden: currentTenant !== "vodafone",
            pages: [
              {
                title: "Overview",
                isMigrated: true,
                iconName: "idea",
                iconPack: "source-mid-render-light-icons",
                jobRoles: ["Engineering", "Operations", "FinOps", "Security"],
                detailLevel: "LOW",
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                isHidden: currentTenant !== "vodafone",
                target:
                  "https://lookerstudio.google.com/embed/reporting/5e4c46ce-8e9d-4d39-a837-2993ef4cbeed/page/p_3q38mnwd8c",
                styles: {
                  minHeight: "450vh",
                },
              },
              {
                title: "Cost",
                isMigrated: true,
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                jobRoles: ["Engineering", "FinOps"],
                detailLevel: "LOW",
                iconName: "savings",
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/5e4c46ce-8e9d-4d39-a837-2993ef4cbeed/page/p_3q38mnwd8c",
                styles: {
                  minHeight: "400vh",
                },
              },
              {
                title: "Availability",
                isMigrated: true,
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                iconName: "padlock-close",
                iconPack: "source-mid-render-light-icons",
                jobRoles: ["Engineering", "Operations"],
                detailLevel: "LOW",
                target:
                  "https://lookerstudio.google.com/embed/reporting/5e4c46ce-8e9d-4d39-a837-2993ef4cbeed/page/p_3q38mnwd8c",
                styles: {
                  minHeight: "380vh",
                },
              },
              {
                title: "Performance",
                isMigrated: true,
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                iconName: "control-panel",
                iconPack: "source-mid-render-light-icons",
                jobRoles: ["Engineering", "Operations", "FinOps"],
                detailLevel: "LOW",
                target:
                  "https://lookerstudio.google.com/embed/reporting/5e4c46ce-8e9d-4d39-a837-2993ef4cbeed/page/p_3q38mnwd8c",
                styles: {
                  minHeight: "380vh",
                },
              },
            ],
          },
        ],
      },
      {
        id: "drcc",
        isHidden: currentTenant !== "vodafone",
        title: "Oracle Cloud DRCC",
        icon: "/assets/icons-home/DRCC.svg",
        termsAndDefinitions: drccDefinitions,
        sections: [
          {
            title: "Cost & Billing",
            description: "Reports related to cost & billing of resources, services, accounts etc. over time.",
            pages: [
              {
                title: "Overview",
                isMigrated: true,
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                iconName: "calendar",
                iconPack: "source-mid-render-light-icons",
                detailLevel: "LOW",
                jobRoles: ["Finance", "Engineering", "Leadership", "FinOps"],
                target:
                  "https://lookerstudio.google.com/embed/reporting/21383948-d787-4bec-b8e7-944746ef76b2/page/p_h4jcbyikbd",
                styles: {
                  minHeight: "230vh",
                },
              },
              {
                title: "Cost Analysis",
                isMigrated: true,
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                iconName: "search",
                iconPack: "source-mid-render-light-icons",
                detailLevel: "HIGH",
                jobRoles: ["Leadership", "Engineering", "Finance", "FinOps"],
                target:
                  "https://lookerstudio.google.com/embed/reporting/21383948-d787-4bec-b8e7-944746ef76b2/page/p_rd60g0ikbd",
                styles: {
                  minHeight: "300vh",
                },
              },
              {
                title: "Cost Breakdown",
                isMigrated: false,
                iconName: "savings",
                iconPack: "source-mid-render-light-icons",
                detailLevel: "LOW",
                jobRoles: ["Leadership", "Finance"],
                target:
                  "https://lookerstudio.google.com/embed/reporting/21383948-d787-4bec-b8e7-944746ef76b2/page/p_u9t2s0ikbd",
                styles: {
                  minHeight: "270vh",
                },
              },
              {
                title: "DRCC Cost Anomaly Detection",
                description: "See the anomalies in your DRCC accounts daily spending",
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                isMigrated: true,
                isDisabled: !this.featureToggles.isFeatureEnabled(userInfo, "anomaly-detection"),
                iconName: "anomaly_detection",
                iconPack: "custom-icons",
                jobRoles: ["Finance", "Engineering", "Leadership", "FinOps"],
                detailLevel: "LOW",
                styles: {
                  minHeight: "300vh",
                },
              },
              // the report is temporarily disabled because it's completely wrong
              ...(environment.production
                ? []
                : [
                    {
                      title: "Cost by Resource Tag",
                      iconName: "price-tag",
                      iconPack: "source-mid-render-light-icons" as IconPack,
                      detailLevel: "LOW" as DetailLevel,
                      jobRoles: ["Engineering", "Operations"] as JobRole[],
                      isMigrated: true,
                      stage: (currentTenant === "vodafone" ? "NEW" : undefined) as DeploymentStage,
                      target:
                        "https://lookerstudio.google.com/embed/reporting/21383948-d787-4bec-b8e7-944746ef76b2/page/p_vs1r90ikbd",
                      styles: {
                        minHeight: "280vh",
                      },
                    },
                  ]),
              {
                title: "Forecast",
                iconName: "chart-line",
                iconPack: "source-mid-render-light-icons",
                isMigrated: false,
                detailLevel: "LOW",
                jobRoles: ["Leadership", "Finance", "FinOps"],
                target:
                  "https://lookerstudio.google.com/embed/reporting/21383948-d787-4bec-b8e7-944746ef76b2/page/p_e3u3sg6gbd",
                styles: {
                  minHeight: "320vh",
                },
              },
            ],
          },
          {
            title: "Asset Inventory",
            description:
              "Aggregated (multi-account and multi org.) asset inventories for various services & resource types. Use it to find resources and for security & compliance (CSB).",
            pages: [
              {
                title: "Overview",
                iconName: "book",
                iconPack: "source-mid-render-light-icons",
                detailLevel: "LOW",
                jobRoles: ["Engineering", "Operations", "Security", "FinOps"],
                isMigrated: true,
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                target:
                  "https://lookerstudio.google.com/embed/reporting/21383948-d787-4bec-b8e7-944746ef76b2/page/p_4fcpmwxxbd",
                styles: {
                  minHeight: "270vh",
                },
              },
              {
                title: "Compute Instances",
                isMigrated: true,
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                detailLevel: "LOW",
                jobRoles: ["Engineering", "Operations", "Security", "Leadership"],
                iconName: "e-sim",
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/21383948-d787-4bec-b8e7-944746ef76b2/page/p_8lwqjftybd",
                styles: {
                  minHeight: "270vh",
                },
              },
              {
                title: "Database Systems",
                isMigrated: true,
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                detailLevel: "LOW",
                jobRoles: ["Engineering", "Operations", "Security", "Leadership"],
                iconName: "sme",
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/21383948-d787-4bec-b8e7-944746ef76b2/page/p_nh652ktybd",
                styles: {
                  minHeight: "270vh",
                },
              },
              {
                title: "Storage Volumes",
                isMigrated: true,
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                iconName: "usb-modem",
                detailLevel: "LOW",
                jobRoles: ["Engineering", "Operations", "Security"],
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/21383948-d787-4bec-b8e7-944746ef76b2/page/p_vowj9rwybd",
                styles: {
                  minHeight: "250vh",
                },
              },
              {
                title: "Object Storage Buckets",
                iconName: "iot",
                iconPack: "source-mid-render-light-icons",
                isMigrated: true,
                detailLevel: "LOW",
                jobRoles: ["Engineering", "Operations", "Security"],
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                target:
                  "https://lookerstudio.google.com/embed/reporting/21383948-d787-4bec-b8e7-944746ef76b2/page/p_i28sbdyxbd",
                styles: {
                  minHeight: "250vh",
                },
              },
              {
                title: "File Systems",
                isMigrated: true,
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                iconName: "document-zip",
                detailLevel: "LOW",
                jobRoles: ["Engineering", "Operations", "Security"],
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/21383948-d787-4bec-b8e7-944746ef76b2/page/p_ots8aswybd",
                styles: {
                  minHeight: "250vh",
                },
              },
              {
                title: "Virtual Networks",
                iconName: "network-tower",
                iconPack: "source-mid-render-light-icons",
                detailLevel: "LOW",
                jobRoles: ["Engineering", "Operations", "Security"],
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                isMigrated: true,
                target:
                  "https://lookerstudio.google.com/embed/reporting/21383948-d787-4bec-b8e7-944746ef76b2/page/p_psnhcswybd",
                styles: {
                  minHeight: "250vh",
                },
              },
            ],
          },
          {
            title: "Cloud Recommendations",
            description:
              "Reports that focus on cost optimisation and recommendations such as changing instance type, removing unused resources, purchasing a commitment to reduce cost etc.",
            pages: [
              {
                title: "Overview",
                isMigrated: true,
                iconName: "idea",
                jobRoles: ["Engineering", "Operations", "FinOps", "Security"],
                detailLevel: "LOW",
                iconPack: "source-mid-render-light-icons",
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                isHidden: currentTenant !== "vodafone",
                target:
                  "https://lookerstudio.google.com/embed/reporting/5e4c46ce-8e9d-4d39-a837-2993ef4cbeed/page/p_3q38mnwd8c",
                styles: {
                  minHeight: "450vh",
                },
              },
              {
                title: "Cost Management",
                isMigrated: true,
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                iconName: "savings",
                jobRoles: ["Engineering", "FinOps"],
                detailLevel: "LOW",
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/5e4c46ce-8e9d-4d39-a837-2993ef4cbeed/page/p_3q38mnwd8c",
                styles: {
                  minHeight: "400vh",
                },
              },
              {
                title: "Performance",
                isMigrated: true,
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                iconName: "control-panel",
                jobRoles: ["Engineering", "Operations", "FinOps"],
                detailLevel: "LOW",
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/5e4c46ce-8e9d-4d39-a837-2993ef4cbeed/page/p_3q38mnwd8c",
                styles: {
                  minHeight: "380vh",
                },
              },
              {
                title: "High Availability",
                isMigrated: true,
                stage: currentTenant === "vodafone" ? "NEW" : undefined,
                iconName: "padlock-close",
                iconPack: "source-mid-render-light-icons",
                jobRoles: ["Engineering", "Operations"],
                detailLevel: "LOW",
                target:
                  "https://lookerstudio.google.com/embed/reporting/5e4c46ce-8e9d-4d39-a837-2993ef4cbeed/page/p_3q38mnwd8c",
                styles: {
                  minHeight: "380vh",
                },
              },
            ],
          },
        ],
      },
      {
        id: "evo",
        title: "EVO Dashboard",
        icon: "/assets/icons-home/oracle.svg",
        isHidden: currentTenant !== "vodafone",
        isDisabled:
          currentTenant !== "vodafone" ||
          !(userInfo?.user?.tenancies ?? []).some((t) => t === "pcs" || t === "evo" || t === "CCE"),
        sections: [
          {
            title: "Cost & Billing",
            description: "Reports related to cost & billing of resources, services, accounts etc. over time.",
            pages: [
              {
                title: "Billing and Spend Management",
                isMigrated: true,
                iconName: "calculator",
                jobRoles: ["FinOps", "Leadership"],
                detailLevel: "HIGH",
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/8631e8be-6752-4437-90a2-a9f6dc42e359/page/p_99a1pv3g4c",
                styles: {
                  minHeight: "350vh",
                },
              },
              {
                title: "Spend by Service view",
                isMigrated: true,
                iconName: "calculator",
                iconPack: "source-mid-render-light-icons",
                jobRoles: ["FinOps", "Leadership"],
                detailLevel: "LOW",
                target:
                  "https://lookerstudio.google.com/embed/reporting/8631e8be-6752-4437-90a2-a9f6dc42e359/page/p_99a1pv3g4c",
                styles: {
                  minHeight: "350vh",
                },
              },
              {
                title: "CUD View",
                isMigrated: true,
                iconPack: "source-mid-render-light-icons",
                jobRoles: ["FinOps"],
                detailLevel: "HIGH",
                iconName: "calculator",
                target:
                  "https://lookerstudio.google.com/embed/reporting/8631e8be-6752-4437-90a2-a9f6dc42e359/page/p_99a1pv3g4c",
                styles: {
                  minHeight: "350vh",
                },
              },
              {
                title: "Reservation By Project",
                isMigrated: true,
                iconName: "deals",
                jobRoles: ["FinOps"],
                detailLevel: "HIGH",
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/8631e8be-6752-4437-90a2-a9f6dc42e359/page/p_99a1pv3g4c",
                styles: {
                  minHeight: "350vh",
                },
              },
              {
                title: "CUD Utilization",
                isMigrated: true,
                iconName: "deals",
                jobRoles: ["FinOps"],
                detailLevel: "HIGH",
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/8631e8be-6752-4437-90a2-a9f6dc42e359/page/p_99a1pv3g4c",
                styles: {
                  minHeight: "350vh",
                },
              },
              {
                title: "Active and In-Active Machines",
                isMigrated: true,
                iconName: "deals",
                jobRoles: ["FinOps", "Engineering", "Operations"],
                detailLevel: "LOW",
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/8631e8be-6752-4437-90a2-a9f6dc42e359/page/p_99a1pv3g4c",
                styles: {
                  minHeight: "350vh",
                },
              },
            ],
          },
        ],
      },
      {
        id: "multi",
        isHidden: currentTenant !== "vodafone",
        title: "Multi Cloud",
        isIconDisabled: true,
        sections: [
          {
            title: "Cost & Billing",
            description: "Reports related to cost & billing of resources, services, accounts etc. over time.",
            pages: [
              {
                title: "Cost Analysis",
                isMigrated: true,
                iconName: "chart-bar",
                jobRoles: ["Leadership"],
                detailLevel: "LOW",
                iconPack: "source-mid-render-light-icons",
                styles: {
                  minHeight: "230vh",
                },
              },
            ],
          },
          {
            title: "IaC",
            description: "Reports related to IaC ",
            pages: [
              {
                title: "IaC Library",
                isMigrated: false,
                isHidden: currentTenant !== "vodafone",
                jobRoles: ["Operations", "Engineering"],
                detailLevel: "LOW",
                isDisabled:
                  currentTenant !== "vodafone" ||
                  !(userInfo?.user?.tenancies ?? []).some((t) => t === "pcs" || t === "CCE"),
                iconName: "calendar",
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/7eeef7ae-f2c5-4999-8498-6882fe18e68d/page/p_srt1p568id",
                styles: {
                  minHeight: "230vh",
                },
              },
            ],
          },
        ],
      },
      {
        id: "3rd party",
        isHidden: currentTenant !== "vodafone",
        title: "3rd Party",
        isIconDisabled: true,
        sections: [
          {
            title: "Mongo DB",
            description: "Reports related to Mongo DB",
            pages: [
              {
                title: "Cost Analysis",
                isMigrated: false,
                iconName: "chart-bar",
                jobRoles: ["FinOps", "Leadership"],
                detailLevel: "LOW",
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/2a9109ac-00ad-46af-8639-4ddb73d670a4/page/p_aswrk8h0cd",
                styles: {
                  minHeight: "230vh",
                },
              },
            ],
          },
          {
            title: "Datadog",
            description: "Reports related to Datadog",
            pages: [
              {
                title: "Cost Analysis",
                isMigrated: false,
                jobRoles: ["FinOps", "Leadership"],
                detailLevel: "LOW",
                iconName: "chart-bar",
                iconPack: "source-mid-render-light-icons",
                target:
                  "https://lookerstudio.google.com/embed/reporting/2d244717-9e75-46b8-b289-2e5d2aecc77a/page/MdknD",
                styles: {
                  minHeight: "230vh",
                },
              },
            ],
          },
        ],
      },
    ];

    for (const csp of result || []) {
      for (const section of csp?.sections || []) {
        for (const page of section?.pages || []) {
          page.url = this.getReportLink(csp, section, page);
        }
      }
    }

    if (customReports.length > 0) {
      for (const csp of result || []) {
        const newSection: Section = {
          title: "Custom Reports",
          isCollapsed: false,
          description:
            "A custom report is created by sharing it. Shared reports can be named and retain their filters for easy access.",
          pages: [],
        };
        for (const section of csp?.sections || []) {
          if (section?.title === "Recently Visited" || section?.title === "Custom Reports") {
            continue;
          }
          for (const page of section?.pages || []) {
            for (const r of customReports) {
              if ((r as ReportShare).Target) {
                const [customReportCsp, customReportSection, customReportPage] = (r as ReportShare).Target.split("/")
                  .filter(Boolean)
                  .filter((s) => s !== "v2" && s !== "vcloudsmart");
                if (
                  customReportCsp !== csp?.id ||
                  customReportSection !== transformLegacySectionName(section?.title) ||
                  customReportPage !== transformLegacyPageName(page?.title)
                ) {
                  continue;
                }
                newSection.pages.push({
                  ...page,
                  title: r.DisplayName ?? `Custom ${page.title}`,
                  customReportId: r.PK.replace("s#", ""),
                  author: r.CreatedBy,
                  queryParams: {
                    shareId: r.PK.replace("s#", ""),
                  },
                  url: (r as ReportShare).Target,
                });
              }
            }
          }
        }
        for (const r of customReports) {
          if (!(r as CustomVCSReport)?.Sections) {
            continue;
          }
          const customReportCsp: CloudProvider = (r as CustomVCSReport)?.CloudProvider;
          if (customReportCsp !== csp?.id) {
            continue;
          }
          let reportTitle = r?.DisplayName ?? "Custom Report";
          try {
            reportTitle +=
              (r?.CreatedBy || "").toLowerCase() === (userInfo?.user?.email || "").toLowerCase()
                ? ""
                : ` (shared by ${this.prettifyAttribute((r?.CreatedBy || "").split("@")[0].split(".")[0])})`;
          } catch (e) {
            this.log.error(e);
          }
          const customReportId: string = r.PK.replace("s#", "");
          newSection.pages.push({
            detailLevel: "LOW",
            iconName: "chart-bar",
            iconPack: "source-mid-render-light-icons",
            isMigrated: true,
            jobRoles: JOB_ROLES,
            title: reportTitle,
            customReportId,
            author: r.CreatedBy,
            queryParams: {
              shareId: r.PK,
            },
            url: `/vcloudsmart/v2/${customReportCsp}/custom-reports/${customReportId}`,
          });
        }
        if (newSection.pages.length > 0) {
          csp.sections = [newSection, ...(csp?.sections ?? [])];
        }
      }
    }

    if (addRecent) {
      for (const csp of result || []) {
        const previousReports: string = localStorage.getItem(`recentlyVisited/${csp.id}`);
        if (!previousReports) {
          continue;
        }
        const parsed: Array<{ title: string; section: string; csp: string }> = JSON.parse(previousReports) || [];
        if (!Array.isArray(parsed) || parsed.length === 0) {
          continue;
        }
        const newSection: Section = {
          title: "Recently Visited",
          isCollapsed: false,
          pages: [],
        };
        for (const section of csp?.sections || []) {
          if (section?.title === "Recently Visited" || section?.title === "Custom Reports") {
            continue;
          }
          for (const page of section?.pages || []) {
            if (
              !parsed.some((p) => {
                return (
                  p.csp === csp?.id &&
                  transformLegacySectionName(section?.title ?? "") === transformLegacySectionName(p.section) &&
                  transformLegacyPageName(page?.title ?? "") === transformLegacyPageName(p.title)
                );
              })
            ) {
              continue;
            }
            newSection.pages.push({
              ...page,
              url: `/vcloudsmart${page.isMigrated ? "/v2" : ""}/${csp.id}/${transformLegacySectionName(
                section.title
              )}/${transformLegacyPageName(page.title)}`,
            });
          }
        }
        if (newSection.pages.length > 0) {
          csp.sections = [newSection, ...(csp?.sections ?? [])];
        }
      }
    }
    return result;
  }

  public getReportLink(csp: Csp, section: Section, page: Page): string {
    if (page.url) {
      return page.url;
    }
    const pageTitle: string = transformLegacyPageName(page?.title);
    const sectionTitle: string = transformLegacySectionName(section?.title);
    const suffix: string = `${csp?.id}/${sectionTitle}/${pageTitle}`;
    const preference: boolean = this.getUserSelectedPagesVersion()?.[suffix] ?? true;
    if (!page.target || (page?.isMigrated && preference)) {
      page.url = `/vcloudsmart/v2/${suffix}`;
    } else {
      page.url = `/vcloudsmart/${suffix}`;
    }
    return page.url;
  }

  public getPage(
    currentTenant: string | undefined,
    csp: string,
    section: string,
    page: string,
    user: UserPermissions,
    vcsConfig?: Array<Csp>
  ): Page | undefined {
    return this.getParams(currentTenant, csp, section, page, user, vcsConfig).page;
  }

  public getParams(
    currentTenant: string | undefined,
    csp: string,
    section: string,
    page: string,
    user: UserPermissions,
    vcsConfig?: Array<Csp>
  ): { csp: Csp; section: Section; page: Page } | undefined {
    for (const c of vcsConfig ?? this.getVCSConfigV2(currentTenant, user)) {
      if (c.id === csp) {
        for (const s of c.sections) {
          if (s.title === section) {
            for (const p of s.pages) {
              if (p.title === page) {
                return { page: p, section: s, csp: c };
              }
            }
          }
        }
      }
    }
  }

  public fmtYear<T extends number | string | Date>(dateString: T): string {
    const tp = typeof dateString as "number" | "object" | "string";
    try {
      const date = tp === "object" ? (dateString as Date) : new Date(dateString);
      return date.getFullYear().toString();
    } catch (e) {
      if (tp === "string") {
        return dateString as string;
      } else {
        return (dateString ?? "").toString();
      }
    }
  }

  public fmtMonth<T extends number | string | Date>(dateString: T, format: "short" | "long" = "short"): string {
    const tp = typeof dateString as "number" | "object" | "string";
    try {
      const date = tp === "object" ? (dateString as Date) : new Date(dateString);
      return `${date.toLocaleString(navigator.language || "en-GB", { month: format })} ${date.getFullYear()}`;
    } catch (e) {
      if (tp === "string") {
        return dateString as string;
      } else {
        return (dateString ?? "").toString();
      }
    }
  }

  public fmtDay<T extends number | string | Date>(dateString: T): string {
    const tp = typeof dateString as "number" | "object" | "string";
    try {
      const date = tp === "object" ? (dateString as Date) : new Date(dateString);
      return `${date.toLocaleString(navigator.language || "en-GB", { day: "numeric" })} ${date.toLocaleString(
        navigator.language || "en-GB",
        { month: "short" }
      )}`;
    } catch (e) {
      if (tp === "string") {
        return dateString as string;
      } else {
        return (dateString ?? "").toString();
      }
    }
  }

  public getCurrency(defaultValue: CurrencyId = "USD"): Currency {
    const [defaultCurrency, ...otherCurrencies] = this.getCurrencies(undefined, defaultValue);
    const cId = localStorage.getItem("currency");
    return otherCurrencies.find(({ id }) => id === cId) ?? defaultCurrency;
  }

  /**
   * DO not use this. Use the getCurrency() above.
   */
  public getCurrencies(currency?: CurrencyId, defaultValue: CurrencyId = "USD"): Array<Currency> {
    const exchangeRates = {
      USD: {
        rate: "1",
        locale: "en-US",
        symbol: "$",
      },
      EUR: {
        rate: "0.898235",
        locale: "de-DE",
        symbol: "€",
      },
      GBP: {
        rate: "0.793368",
        locale: "en-GB",
        symbol: "£",
      },
    };

    let matchingLocales = [];

    try {
      matchingLocales = Intl.NumberFormat.supportedLocalesOf([navigator.language]);
    } catch (e) {}

    const oldRate = new Decimal(exchangeRates[defaultValue].rate);

    if (defaultValue === "GBP") {
      this.log.info({ msg: "azure detected, currencies adjusted", old: exchangeRates });
      exchangeRates.USD.rate = new Decimal(exchangeRates.USD.rate).div(oldRate).toString();
      exchangeRates.EUR.rate = new Decimal(exchangeRates.EUR.rate).div(oldRate).toString();
      exchangeRates.GBP.rate = "1";
      this.log.info({ msg: "azure detected, currencies adjusted", new: exchangeRates });
    } else if (defaultValue === "EUR") {
      this.log.info({ msg: "drcc detected, currencies adjusted", old: exchangeRates });
      exchangeRates.USD.rate = new Decimal(exchangeRates.USD.rate).div(oldRate).toString();
      exchangeRates.GBP.rate = new Decimal(exchangeRates.GBP.rate).div(oldRate).toString();
      exchangeRates.EUR.rate = "1";
    }

    if (currency && exchangeRates[currency] !== undefined) {
      const filteredExchangeRate = exchangeRates[currency];
      const locale = matchingLocales.length > 0 ? matchingLocales[0] : filteredExchangeRate.locale;
      return [
        {
          exchangeRate: new Decimal(filteredExchangeRate.rate),
          id: currency,
          text: currency,
          symbol: filteredExchangeRate.symbol,
          locale: filteredExchangeRate.locale,
          format: new Intl.NumberFormat(locale, {
            ...currencyOptions,
            currency,
          }),
        },
      ];
    }
    return Object.entries(exchangeRates).map(([currency, { locale, rate, symbol }]) => {
      return {
        exchangeRate: new Decimal(rate),
        id: currency as CurrencyId,
        text: currency as CurrencyId,
        locale,
        symbol,
        format: new Intl.NumberFormat(matchingLocales.length > 0 ? matchingLocales[0] : locale, {
          ...currencyOptions,
          currency,
        }),
      };
    });
  }

  public getUserSelectedPagesVersion(): { [key: string]: boolean } {
    const prefs = localStorage.getItem("vCloudSmartSelectedVersion");
    if (prefs) {
      return JSON.parse(prefs);
    } else {
      const newPrefs = {};
      localStorage.setItem("vCloudSmartSelectedVersion", JSON.stringify(newPrefs));
      return newPrefs;
    }
  }

  public setUserSelectedPagesVersion(csp: string, section: string, page: string, selectMigrated: boolean) {
    let userSelectedVersion = {};
    const savedUserSelectedVersion = JSON.parse(localStorage.getItem("vCloudSmartSelectedVersion"));

    if (savedUserSelectedVersion) {
      userSelectedVersion = { ...savedUserSelectedVersion };
    }

    userSelectedVersion[`${csp}/${section}/${page}`] = selectMigrated;
    localStorage.setItem("vCloudSmartSelectedVersion", JSON.stringify(userSelectedVersion));
  }
}
