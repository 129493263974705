<!-- customise table columns -->
<div id="column-select-accordion" class="mb-1">
  <div *ngIf="columns.length > 1" class="card br-6 box-shadow border-0">
    <div class="card-header border-0" id="column-select-container">
      <h5 class="my-2 d-flex justify-content-between">
        <span class="d-inline-flex align-items-center">
          Customise the table to view data that best fit your needs.
        </span>
        <ng-container [ngSwitch]="selectColumnCardShow">
          <app-vficon
            *ngSwitchCase="true"
            iconPack="source-system-icons"
            iconName="chevron-up"
            data-toggle="collapse"
            data-target="#column-container"
            aria-expanded="true"
            aria-controls="column-container"
            style="cursor: pointer"
            (click)="selectColumnCardShowHide()"
          ></app-vficon>
          <app-vficon
            *ngSwitchCase="false"
            iconPack="source-system-icons"
            iconName="chevron-down"
            data-toggle="collapse"
            data-target="#column-container"
            aria-expanded="true"
            aria-controls="column-container"
            style="cursor: pointer"
            (click)="selectColumnCardShowHide()"
          ></app-vficon>
        </ng-container>
      </h5>
    </div>
    <div class="hr" *ngIf="selectColumnCardShow"></div>
    <div
      id="column-container"
      class="collapse"
      aria-labelledby="column-select-container"
      data-parent="#column-select-accordion"
    >
      <div class="card-body">
        <table class="table px-3">
          <tbody>
            <tr class="mx-2" *ngFor="let columnBatch of batchesData">
              <td *ngFor="let column of columnBatch" class="py-3">
                <input
                  type="checkbox"
                  [disabled]="disabled"
                  class="mr-2"
                  style="position: relative; top: 1px"
                  [name]="column.title"
                  [id]="column.title"
                  [checked]="column.selected"
                  (click)="selectColumnChangeHandler($event, column)"
                />
                <label [for]="column.title">{{ column.label || column.title }}</label>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex">
          <div class="column-select-button-container">
            <button [disabled]="disabled" type="button" class="btn btn-primary mr-3" (click)="showSelectedColumns()">
              Save
            </button>
            <button [disabled]="disabled" type="button" class="btn btn-dark" (click)="showAllColumns()">
              Show All
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="!disableDownload"
    class="dropdown border-0 d-flex justify-content-end align-items-center pl-4"
    style="margin-left: auto"
  >
    <p class="h6 mt-3">Download</p>
    <button
      [disabled]="disabled"
      class="btn"
      type="button"
      id="dropdownMenuButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      data-cy="download-dropdown"
    >
      <app-vficon iconName="download" iconPack="source-mid-render-light-icons"></app-vficon>
    </button>
    <div class="dropdown-menu dropdown-menu-right">
      <button [disabled]="disabled" class="dropdown-item" (click)="clickDownload('all')" data-cy="dl-all">
        Download
      </button>
      <!--      <button class="dropdown-item" (click)="clickDownload('filtered')" data-cy="dl-filtered">Download Filtered</button>-->
      <button
        [disabled]="disabled"
        *ngIf="downloadSelected"
        class="dropdown-item"
        (click)="clickDownload('selected')"
        data-cy="dl-selected"
      >
        Download Selected
      </button>
    </div>
  </div>
</div>
