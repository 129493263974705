export const URL_REGEX =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;
export const EMPTY_VALS: string[] = [
  "",
  "--",
  "na",
  "example@vodafone.com",
  "null",
  "unknown",
  "None",
  "n/a",
  "NA",
  "N/A",
  "0",
];
export const REGEX_QUOTE = /"/g;
export const REGEX_POINTLESS_NAME = /(([-_a-z0-9]{2,}) +)+(name)$/gi;
export const REGEX_ID_CASE = /(([-_a-z0-9]{2,}) +)+(id)$/gi;
export const REGEX_CAMEL_CASE = /([a-z])([A-Z])/g;
export const WHITESPACE_REGEX = /\s+/g;
export const PARENTHESIS_REGEX = /[()]/g;
export const AMPERSAND_REGEX = /&/g;

const CACHE_transformLegacySectionName: { [s: string]: string } = {};

export const transformLegacySectionName = (s: string): string => {
  if (!s) {
    s = "";
  }
  if (CACHE_transformLegacySectionName[s]) {
    return CACHE_transformLegacySectionName[s];
  }
  const v = s
    .replace(WHITESPACE_REGEX, "-")
    .replace(PARENTHESIS_REGEX, "")
    .replace(AMPERSAND_REGEX, "and")
    .toLowerCase();
  CACHE_transformLegacySectionName[s] = v;
  return v;
};

const CACHE_transformLegacyPageName: { [s: string]: string } = {};

export const transformLegacyPageName = (s: string): string => {
  if (!s) {
    s = "";
  }
  if (CACHE_transformLegacyPageName[s]) {
    return CACHE_transformLegacyPageName[s];
  }
  const v = s.replace(WHITESPACE_REGEX, "-").replace(PARENTHESIS_REGEX, "").toLowerCase();
  CACHE_transformLegacyPageName[s] = v;
  return v;
};
// https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression
export const REGEX_EMAIL =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
