import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import Swal, { SweetAlertResult } from "sweetalert2";
import { LookerStudioService } from "./looker-studio.service";
import { TenantService } from "./tenant.service";
import { Currency } from "../models/currency";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class EventsService {
  public loading = new BehaviorSubject<boolean>(false);

  public readonly currency = new BehaviorSubject<Currency>(
    localStorage.getItem("currency")
      ? this.vcs.getCurrencies().filter((c) => c?.id === localStorage.getItem("currency"))[0]
      : this.vcs.getCurrencies()[0]
  );

  // azure has a different default currency which means different exchange rates
  // all azure reports should use the below and not the above
  public readonly azureCurrency = new BehaviorSubject<Currency>(
    localStorage.getItem("currency")
      ? this.vcs.getCurrencies(undefined, "GBP").filter((c) => c?.id === localStorage.getItem("currency"))[0]
      : this.vcs.getCurrencies(undefined, "GBP")[0]
  );

  // drcc has a different default currency which means different exchange rates
  // all drcc reports should use the below and not the above
  public readonly drccCurrency = new BehaviorSubject<Currency>(
    localStorage.getItem("currency")
      ? this.vcs.getCurrencies(undefined, "EUR").filter((c) => c?.id === localStorage.getItem("currency"))[0]
      : this.vcs.getCurrencies(undefined, "EUR")[0]
  );

  public readonly userFeedbackState = new BehaviorSubject<{ open: boolean; trigger: string }>({
    open: false,
    trigger: "",
  });
  public readonly sideFiltersState = new BehaviorSubject<boolean>(false);
  public readonly sideHelpState = new BehaviorSubject<boolean>(false);

  public readonly aiPopUpVisible = new BehaviorSubject<boolean>(false);
  public readonly gcpAnnouncementsPopUpVisible = new BehaviorSubject<boolean>(false);

  constructor(private vcs: LookerStudioService, private tenant: TenantService) {}

  /**
   * NOTE: this MUST be used for displaying all errors to users in the whole application to ensure standardised format.
   *
   * @param e
   * @param {string} action e.g. 'Download Data', 'Load Data', 'Update Account Details', ...
   * @param {string} customMessage don't set it unless you have a very good reason to do so
   * @returns {Promise<SweetAlertResult<Awaited<unknown>>>}
   */
  public displayErrorToUser(
    e: Error | HttpErrorResponse,
    action: string,
    customMessage?: string
  ): Promise<SweetAlertResult<Awaited<unknown>>> {
    return Swal.fire({
      icon: "error",
      confirmButtonColor: this.tenant.colors.primary,
      title: `Failed to ${action}`,
      text:
        customMessage ||
        ((e as HttpErrorResponse).error || {}).errorMessage ||
        ((e as HttpErrorResponse).error || {}).message ||
        e.message ||
        "Something went wrong.",
    });
  }

  public changeCurrency(currency: Currency): void {
    localStorage.setItem("currency", currency?.id);
    if (this.currency?.getValue()?.id !== currency?.id) {
      this.currency.next(currency);
    }
  }

  public changeCurrencyDrcc(currency: Currency): void {
    localStorage.setItem("currency", currency?.id);
    if (this.drccCurrency?.getValue()?.id !== currency?.id) {
      this.drccCurrency.next(currency);
    }
  }

  public changeCurrencyAzure(currency: Currency): void {
    localStorage.setItem("currency", currency?.id);
    if (this.azureCurrency?.getValue()?.id !== currency?.id) {
      this.azureCurrency.next(currency);
    }
  }
}
