import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { LoggerService } from "../../services/logger.service";
import { VFIconComponent } from "../vficon/vficon.component";
import { CommonModule } from "@angular/common";
import { TableColumn } from "../../models/common-table";

@Component({
  standalone: true,
  imports: [CommonModule, VFIconComponent],
  selector: "app-customize-table-columns",
  templateUrl: "./customize-table-columns.component.html",
  styleUrls: ["./customize-table-columns.component.scss"],
})
export class CustomizeTableColumnsComponent implements OnChanges {
  @Input() columns: TableColumn[];
  @Output() changeEvent: EventEmitter<Partial<TableColumn>[]> = new EventEmitter<Partial<TableColumn>[]>();

  @Input() disabled: boolean = false;

  public selectedColumns: Partial<TableColumn>[];
  public selectColumnCardShow: boolean = false;
  public batchesData = [];

  // download
  @Output() downloadEvent = new EventEmitter();
  @Input() isDynamic: boolean = false;
  @Input() disableDownload: boolean = true;
  @Input() downloadSelected: boolean = false;

  constructor(private log: LoggerService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (JSON.stringify(changes?.columns?.currentValue) !== JSON.stringify(changes?.columns?.previousValue))
      this.selectedColumns = this.columns.map((column) => ({
        title: column.title,
        selected: column.selected,
        label: column.label || column.title,
      }));

    this.batchesData = this.batch(this.filterOutSelectBox(this.selectedColumns), 5);
  }

  public selectColumnCardShowHide(): void {
    this.selectColumnCardShow = !this.selectColumnCardShow;
  }

  public clickDownload(option: string): void {
    this.downloadEvent.emit(option);
  }

  public batch<T>(iterable: Array<T>, batchSize: number): Array<Array<T>> {
    const nBatches = Math.ceil(iterable.length / batchSize);
    const batches = [];
    for (let batchIdx = 0; batchIdx < nBatches; batchIdx++) {
      batches.push(iterable.slice(batchIdx * batchSize, (batchIdx + 1) * batchSize));
    }
    return batches;
  }

  public filterOutSelectBox(selectedColumns: Partial<TableColumn>[]): Partial<TableColumn>[] {
    return selectedColumns.filter((c) => !!c.title);
  }

  public selectColumnChangeHandler(event: MouseEvent, column: Partial<TableColumn>): void {
    this.log.info("(de-)selecting column ", column);
    column.selected = (<HTMLInputElement>(<MouseEvent>event).target).checked;
  }

  public showSelectedColumns(): void {
    for (const c of this.selectedColumns) {
      if (c.title === "selector") {
        c.selected = true;
      }
    }
    this.changeEvent.emit(this.selectedColumns);
  }

  public showAllColumns(): void {
    for (const column of this.selectedColumns) {
      column.selected = true;
    }
    this.showSelectedColumns();
  }
}
