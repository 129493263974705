import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { LookerStudioService } from "../../shared/services/looker-studio.service";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { LoggerService } from "../../shared/services/logger.service";
import { TitleWrapperService } from "../../shared/services/title-wrapper.service";
import { SizeChangeEvent } from "../../shared/models/types";
import { EventsService } from "../../shared/services/events.service";
import { CommonModule } from "@angular/common";
import { VFIconComponent } from "../../shared/component/vficon/vficon.component";
import { FormsModule } from "@angular/forms";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { ReleasePhaseLabelComponent } from "../../shared/component/release-phase-label/release-phase-label.component";
import { map, switchMap } from "rxjs/operators";
import { AuthorisationService } from "../../shared/services/authorisation.service";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { AuthenticationService } from "../../shared/services/authentication.service";
import { BehaviorSubject, forkJoin, Subscription } from "rxjs";
import { MetricCollectionService } from "../../shared/services/metric-collection.service";
import { Csp, Page, Section } from "../../shared/models/vcloudsmart";
import { Currency, CurrencyId } from "../../shared/models/currency";
import { iframeSizes } from "../../shared/constant/customisation";
import { CSP } from "../../shared/models/csp-api";
import { CLOUD_PROVIDERS_ENABLED } from "../../shared/constant/llm";
import { CloudProvider } from "../../shared/models/vcloud-api";
import { VCloudSmartApiService } from "../../shared/services/v-cloud-smart-api.service";
import { FeatureTogglesService } from "../../shared/services/feature-toggles.service";

interface Parameters {
  csp: string;
  section: string;
  page: string;
  version: string;
}

const REGEX_VCS_URL = new RegExp("^/vcloudsmart(/v[0-9]+)", "i");

@Component({
  standalone: true,
  imports: [
    CommonModule,
    VFIconComponent,
    FormsModule,
    CollapseModule,
    RouterModule,
    ReleasePhaseLabelComponent,
    TooltipModule,
  ],
  selector: "app-looker-studio-navigation",
  templateUrl: "./looker-studio-navigation.component.html",
  styleUrls: ["./looker-studio-navigation.component.scss"],
})
export class LookerStudioNavigationComponent implements OnInit, OnDestroy {
  @Output()
  public sizeChanged: BehaviorSubject<SizeChangeEvent> = new BehaviorSubject({
    text: Object.keys(iframeSizes).includes(localStorage.getItem("vcsRatio"))
      ? localStorage.getItem("vcsRatio")
      : "full width",
    value: Object.keys(iframeSizes).includes(localStorage.getItem("vcsRatio"))
      ? iframeSizes[localStorage.getItem("vcsRatio")]
      : 1,
  });

  public timeouts: number[] = [];

  @Output()
  public currencyChange = new EventEmitter<Currency>();
  @Output()
  public currencyChangeAzure = new EventEmitter<Currency>();
  @Output()
  public currencyChangeDrcc = new EventEmitter<Currency>();

  private subscriptions: Subscription[] = [];
  public urlParams: Partial<Parameters>;
  public reportState: {
    csp: Csp;
    section: Section;
    page: Page;
  };
  public currencyChangeInProgress = true;

  public Object = Object;

  public iframeSizesKeys = Object.keys(iframeSizes);
  public iframeSizes: { [key: string]: number } = iframeSizes;
  public vcsConfig: Array<Csp> = [];

  public currencies: Currency[] = [];

  public csp: CSP;
  public defaultCurrency: CurrencyId;

  constructor(
    private log: LoggerService,
    private featureToggles: FeatureTogglesService,
    private metrics: MetricCollectionService,
    private route: ActivatedRoute,
    private looker: LookerStudioService,
    private vcs: VCloudSmartApiService,
    private title: TitleWrapperService,
    public router: Router,
    private eventsService: EventsService,
    private authorisation: AuthorisationService,
    private authentication: AuthenticationService
  ) {}

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.timeouts.forEach((s) => clearTimeout(s));
  }

  public get currentTenant(): string {
    return this.authentication.tenant;
  }

  public get currentSize(): string {
    return this.sizeChanged.getValue().text;
  }

  public handleSizeChange(sizeName: string): void {
    if (this.currentSize !== sizeName) {
      this.metrics.reportWidthChanged(this.router.url, sizeName);
      this.sizeChanged.next({ text: sizeName, value: this.iframeSizes[sizeName] });
      window.dispatchEvent(new Event("resize"));
      localStorage.setItem("vcsRatio", sizeName);
    }
  }

  private getParams(_params: { [k: string]: any }): Partial<Parameters> {
    if (_params === null || _params === undefined) {
      return this.getParams({});
    }
    if (location.pathname === "/vcloudsmart/assistant") {
      return {};
    }
    const params = { ..._params };
    const segments: string[] = (this.router?.url ?? "")
      .split("?")[0]
      .replace(REGEX_VCS_URL, "")
      .split("/")
      .filter(Boolean);
    const slices: string[] = segments.slice(0, 3);
    const sl: number = slices?.length ?? 0;
    if (!params.csp && sl >= 1) {
      params.csp = slices[0];
    }
    if (!params.section && sl >= 2) {
      params.section = slices[1];
    }
    if (!params.page && sl >= 3) {
      params.page = slices[2];
    }
    if (!params.version) {
      const match = REGEX_VCS_URL.exec(this.router.url);
      if ((match?.length ?? 0) >= 2) {
        params.version = match[1].slice(1);
      }
    }
    for (const k in params) {
      params[k] = decodeURIComponent(params[k]);
    }
    return params as Parameters;
  }

  ngOnInit(): void {
    this.log.warning("initialising looker studio navigation component");
    this.csp = location.pathname
      .split("/")
      .filter(Boolean)
      .find((c) => CLOUD_PROVIDERS_ENABLED.includes(c as CloudProvider)) as CSP;
    let defaultCurrency: CurrencyId = "USD";
    if (this.csp === "drcc") {
      defaultCurrency = "EUR";
    } else if (this.csp === "azure") {
      defaultCurrency = "GBP";
    }
    this.currency.id = defaultCurrency;
    this.currencies = this.looker.getCurrencies(undefined, defaultCurrency);
    this.defaultCurrency = defaultCurrency;
    this.log.info({
      currencies: this.currencies,
      defaultCurrency,
      csp: this.csp,
    });

    this.subscriptions.push(
      this.route.params
        .pipe(
          map((routeParams: Parameters) => {
            this.urlParams = this.getParams(routeParams);
            this.log.info({ params: this.urlParams });
            this.title.setVcsTitle();
          }),
          switchMap(() => {
            return forkJoin({
              user: this.authorisation.getUserPermissions({
                attributes: ["allowed_tenants", "current_tenant", "groups", "permissions", "tenancies"],
              }),
              customReports: this.vcs.getCustomReports(),
            });
          })
        )
        .subscribe(({ user, customReports }) => {
          const enableCustomReports: boolean = this.featureToggles.isFeatureEnabled(user, "custom-reports");
          this.vcsConfig = this.looker.getVCSConfig(
            this.currentTenant,
            user,
            true,
            enableCustomReports ? customReports : []
          );
          this.reportState = this.looker.getParams(
            this.currentTenant,
            this.urlParams.csp,
            this.urlParams.section,
            this.urlParams.page,
            user
          );
          this.handleCurrencyChange(this.currency.id);
        })
    );
  }

  public getReportLink(csp: Csp, section: Section, page: Page): string {
    return this.looker.getReportLink(csp, section, page);
  }

  public get migratedVersionSelected(): boolean {
    return this.urlParams?.version === "v2";
  }

  public get isCurrentPageMigrated(): boolean {
    // vcloud route are defined as vcloudsmart/version/csp/cost-and-billing/report-name
    return !!this.reportState?.page?.isMigrated;
  }

  public get currency(): Currency {
    return this.eventsService?.currency?.getValue();
  }

  public get currencyAzure(): Currency {
    return this.eventsService?.azureCurrency?.getValue();
  }

  public get currencyDrcc(): Currency {
    return this.eventsService?.drccCurrency?.getValue();
  }

  public get userVersionPreferences(): { [key: string]: boolean } {
    return this.looker.getUserSelectedPagesVersion();
  }

  public isActive(csp: Csp, section: Section, page: Page): boolean {
    const tmpActiveRoutParams = this.getParams(this.route.params);
    return (
      tmpActiveRoutParams?.csp === csp?.id &&
      tmpActiveRoutParams?.section === section?.title &&
      tmpActiveRoutParams?.page === page?.title
    );
  }

  public onVersionChange(): void {
    const isMigrated = !this.migratedVersionSelected;
    this.looker.setUserSelectedPagesVersion(
      this.urlParams.csp,
      this.urlParams.section,
      this.urlParams.page,
      isMigrated
    );
    if (isMigrated) {
      this.router.navigate(["/vcloudsmart/v2", this.urlParams.csp, this.urlParams.section, this.urlParams.page]);
    } else {
      this.metrics.reportSwitchedToLegacy(this.router.url);
      this.router.navigate(["/vcloudsmart", this.urlParams.csp, this.urlParams.section, this.urlParams.page]);
    }
  }

  public handleCurrencyChange(currencyId: CurrencyId): void {
    if (this.currency?.id !== currencyId) {
      this.metrics.reportCurrencyChanged(currencyId);
    }

    const newCurrency = this.looker.getCurrencies(currencyId)[0];
    const newCurrencyAzure = this.looker.getCurrencies(currencyId, "GBP")[0];
    const newCurrencyDrcc = this.looker.getCurrencies(currencyId, "EUR")[0];

    this.log.info({
      newCurrency,
      oldCurrency: this.currency,
      oldCurrencyAzure: this.currencyAzure,
      oldCurrencyDrcc: this.currencyDrcc,
    });
    this.currencyChangeInProgress = true;

    this.currencyChange.next(newCurrency);
    this.currencyChangeAzure.next(newCurrencyAzure);
    this.currencyChangeDrcc.next(newCurrencyDrcc);

    this.eventsService.changeCurrency(newCurrency);
    this.eventsService.changeCurrencyAzure(newCurrencyAzure);
    this.eventsService.changeCurrencyDrcc(newCurrencyDrcc);

    this.timeouts.push(
      setTimeout(() => {
        this.currencyChangeInProgress = false;
      }, 2000) as unknown as number
    );
  }

  protected readonly location = location;

  public get isCustomReport(): boolean {
    const s: string = location?.pathname || "";
    return s.includes("/custom-reports/") && !!s.split("/").slice(-1)[0];
  }
}
